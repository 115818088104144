import { runSagaMiddleware, store } from './src/store/createStore';
import { Provider } from 'react-redux';
import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './src/utils/query-client';

export const wrapWithProvider = ({ element }: { element: any }) => {
  runSagaMiddleware();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>{element}</Provider>
    </QueryClientProvider>
  );
};
