import { DocumentNode } from '../../types/RealtaDocument';
import { SELECT_DOCUMENT_NODE } from './actions';

export interface DocumentNodeState {
  node: DocumentNode;
}

const initialState: DocumentNodeState = {
  node: {
    uuid: '',
    startBoundryY: 0,
    endBoundryY: 0,
    startBoundryPage: 0,
    endBoundryPage: 0,
  },
};

export interface Action {
  type: string;
  payload?: any;
}

export const documentNodeReducer = (state: DocumentNodeState = initialState, action: Action): DocumentNodeState => {
  const { type, payload } = action;
  switch (type) {
    case SELECT_DOCUMENT_NODE: {
      return {
        ...state,
        node: payload,
      };
    }

    default:
      return state;
  }
};
