import { apollo } from '../apollo/client';
import gql from 'graphql-tag';

const SAVE_ACCESS_QUERY = gql`
  mutation SAVE_ACCESS_QUERY($accessInput: AccessInput!) {
    saveAccess(accessInput: $accessInput) {
      id
      projectId
      publicAccess
    }
  }
`;
const saveAccess = async (projectId: string, publicAccess: string, users: any[]) => {
  const { data } = await apollo.mutate({
    mutation: SAVE_ACCESS_QUERY,
    variables: {
      accessInput: {
        projectId,
        publicAccess,
        users,
      },
    },
  });
  return { ...data.accessInput };
};

const GET_ALL_PUBLIC_PROJECTS = gql`
  query PUBLIC_PROJECTS {
    publicProjects
  }
`;

const getAllPublicProjects = async () => {
  const { data } = await apollo.mutate({
    mutation: GET_ALL_PUBLIC_PROJECTS,
  });
  return { ...data.publicProjects };
};

export const accessService = {
  saveAccess,
  getAllPublicProjects,
};
