import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

// A custom theme for this app
const theme = createTheme({
  realta: createColor('#000'),
  palette: {
    primary: {
      main: '#B1FFF0',
      light: 'rgba(177, 255, 240, 0.15)',
    },
    secondary: {
      main: '#FF2FF4',
      light: '#ffacfb',
      superLight: '#ffc1fc',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f9f9f9',
      paper: '#f2f2f2',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'Roboto Mono', 'Arial', 'sans-serif'].join(','),
  },
  overrides: {
    MuiToolbar: {
      dense: {
        height: '38px',
        minHeight: '38px',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow:
          '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
      },
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(0,0,0,0.87)',
        '&$focused': {
          color: 'rgba(0,0,0,0.87)',
        },
      },
    },
  },
});

export default theme;
