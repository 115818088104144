import { takeEvery, put, call } from 'redux-saga/effects';
import { UPDATE_ACCESS_CONTROL, LOAD_PUBLIC_PROJECTS_ID_REQUEST, loadPublicProjectsIdSuccess } from './actions';
import { accessService } from '../../services/accessService';
import { Project, allProjectsLoaded, RuleSuite, hideParentLink } from '../project';
import { projectService } from '../../services';
import * as _ from 'lodash';

function* updateAccessControl({ payload }: any) {
  const { projectId, publicAccess, users } = payload;
  yield call(accessService.saveAccess, projectId, publicAccess, users);
}

function* loadPublicProjectsIdReqSaga() {
  const projectIds: string[] = yield call(accessService.getAllPublicProjects);
  const allProjects: any = [];
  const filterProjects: any = Object.values(projectIds);
  for (const id of Object.values(projectIds)) {
    const project: Project = yield call(projectService.getProject, id);
    project.ruleSuites &&
      project.ruleSuites.map((ruleSuite: RuleSuite) => {
        if (ruleSuite.type === 'project' && ruleSuite.isLinked) {
          _.remove(filterProjects, (id) => id === ruleSuite.id);
        }
      });
    allProjects.push(project);
  }

  yield put(hideParentLink(filterProjects));
  yield put(allProjectsLoaded(allProjects));
  yield put(loadPublicProjectsIdSuccess(projectIds));
}

export const accessSaga = [
  takeEvery(UPDATE_ACCESS_CONTROL, updateAccessControl),
  takeEvery(LOAD_PUBLIC_PROJECTS_ID_REQUEST, loadPublicProjectsIdReqSaga),
];
