import {
  put,
  takeLeading,
  select,
  call,
  takeEvery,
  take,
  debounce,
  throttle,
  fork,
  actionChannel,
  cancel,
  delay,
} from 'redux-saga/effects';
import {
  CREATE_NEW_SEGMENT,
  createSegmentSuccess,
  SAVE_RULE,
  syncSegmentUpdate,
  createSegmentOptimistic,
  saveSegmentOptimistic,
  EXPORT_RULES,
  COPY_RULES,
  SAVE_SEGMENT_SELECTION,
  DELETE_SEGMENT,
  deleteSegmentOptimistic,
  segmentDeletedSuccess,
  syncSegmentDelete,
  OPTIMISTIC_SAVE_SEGMENT,
  EXPORT_XLS,
  masterAtomListUpdated,
  COLLAPSE_ALL_UNSELECTED,
  LOAD_ALL_SEGMENTS,
  loadAllSegmentsOptimistic,
  PROCESS_SEGMENT_UPDATE_SUBSCRIPTION,
  PROCESS_SEGMENT_DELETE_SUBSCRIPTION,
  ACTIVATE_RULE,
} from './actions';
import { segmentSelectors } from './selectors';
import { Segment } from './reducer';
import { workspaceService as service, util } from '../../services';

import * as _ from 'lodash';
import { rerunTestCases } from '../testCase';

import { workspaceSelectors, LOAD_WORKSPACE_SUCCESS } from '../workspace';
import * as fs from 'file-saver';
import { settingSelectors, uncollapseSegment, collapseSegment } from '../setting';
import { Project } from '../project';
import { projectSelectors } from '../project';

function* createSegment(payload: any): any {
  const { type } = payload;
  yield put(createSegmentOptimistic({ ...payload }, type));
  const data = yield call(service.createSegment, payload);
  yield put(createSegmentSuccess(data));
}

function* createSegmentRequestSaga({ payload }: any) {
  yield call(createSegment, payload);
  if (payload.type === 'collapsed') {
    setTimeout(() => {
      const collapsedEl = document.getElementById(payload.id);
      if (collapsedEl && !util.isElementVisible(collapsedEl)) {
        collapsedEl?.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }, 100);
  }
}

function* collapseAllUnselectedSaga(): any {
  const unselectedSegments = yield select(segmentSelectors.unselectedSegments);
  for (let i = 0; i < unselectedSegments.length; i++) {
    const segment = unselectedSegments[i];
    yield put(collapseSegment(segment.startLegalTextId, segment.endLegalTextId));
  }
  setTimeout(() => {
    const element = document.getElementById(`segment-container`);
    if (!util.isElementVisible(element)) {
      element?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, 200);
}

function* saveRuleSaga({ payload }: any): any {
  const segment = yield select(segmentSelectors.segmentById(payload.id));
  let segmentToSave = {
    ...segment,
    ...payload,
  };
  if (!_.isEqual(segment, segmentToSave)) {
    segmentToSave = {
      ...segmentToSave,
      version: ++segment.version,
    };

    yield put(saveSegmentOptimistic(segmentToSave));
    yield fork(service.saveSegment, segmentToSave);
  }
}

function* exportRulesSaga(): any {
  const segments = yield select(segmentSelectors.selectedSegments);
  const rules = util.prepareForExport(segments);
  download(rules);
}

function* exportXlsSaga(): any {
  const segments: Segment[] = yield select(segmentSelectors.generateAllSegmentTypes);
  // @ts-ignore
  const htmlString = yield select(workspaceSelectors.currentConvertedHTML);
  const data = segments.reduce((accumulator: any, { rule, startLegalTextId, endLegalTextId }: Segment) => {
    const excelRowData: any = [];
    excelRowData.push(util.legalTextsInBetween(htmlString, startLegalTextId, endLegalTextId));
    excelRowData.push(rule ? rule : '\n');

    accumulator.push(excelRowData);

    return accumulator;
  }, [] as any);

  downloadExcel(data).then();
}

function* copyRulesSaga(): any {
  const segments = yield select(segmentSelectors.selectedSegments);
  const rules = util.prepareForExport(segments);
  copyToClipboard(rules);
}

function copyToClipboard(text: string) {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

async function downloadExcel(data: any) {
  const finalOutput = await util.prepareWorkbook(data);
  fs.saveAs(finalOutput, 'rules.xlsx');
}

function download(text: string) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', 'rules.txt');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

function* deleteSegmentSaga({ payload: { id } }: any): any {
  const segment = yield select(segmentSelectors.segmentById(id));
  const isWatchModeOn = yield select(settingSelectors.isWatchModeOn);
  if (segment) {
    yield put(deleteSegmentOptimistic(id));
    if (segment.rule && isWatchModeOn) {
      yield put(rerunTestCases());
    }
    const deletedId = yield call(service.deleteSegment, id);
    yield put(segmentDeletedSuccess(deletedId));
  }
}

function* runAllTestCases(): any {
  if (yield select(settingSelectors.isWatchModeOn)) {
    yield put(rerunTestCases());
  }
}

function* determineAtoms(): any {
  yield delay(500);
  let atoms: string[] = ['Atom'];
  // @ts-ignore
  const segments = yield select(segmentSelectors.allSegments);
  segments.forEach(({ rule }: Segment) => {
    const atomList = _.words(
      rule,
      /^((Atom|Numeric|Boolean|String|DateTime|Date|Duration|namespace|closespace)(\s.*\w)(\.*))/gm
    )
      .map((item) => item.split(/\s/)[1].trim())
      .filter((item) => !!item);
    atoms = _.union(atoms, atomList);
  });

  atoms = _.sortBy(atoms);
  const masterAtomList = yield select(segmentSelectors.masterAtomList);
  if (!_.isEqual(atoms, masterAtomList)) {
    yield put(masterAtomListUpdated(atoms));
  }
}

function* watchMasterAtomList(): any {
  const workspaceLoaded = yield actionChannel(LOAD_WORKSPACE_SUCCESS);
  yield take(workspaceLoaded);
  const saveRuleChan = yield actionChannel(SAVE_RULE);

  let task: any;
  while (true) {
    task = yield fork(determineAtoms);
    yield take(saveRuleChan);
    yield cancel(task);
  }
}

function* loadAllSegmentsSaga({ payload }: any): any {
  const { projectId } = payload;
  const combinedSegments: any = [];

  const project: Project = yield select(projectSelectors.projectById(projectId));
  const workspaces: any = yield select(projectSelectors.allWorkspaceIdName(project));
  const flatWorkspaces: any = (workspaces && workspaces.length > 0 && _.flattenDeep(workspaces)) || [];
  for (let i = 0; !_.isNil(flatWorkspaces[i]) && flatWorkspaces[i].id && i < flatWorkspaces.length; i++) {
    const segments: any = yield call(service.getAllSegmentsByWorkspaceId, flatWorkspaces[i].id);

    const segmentsAsArray = Object.values(segments);
    combinedSegments.push(segmentsAsArray);
  }
  const allSegments: Segment[] = _.flattenDeep(combinedSegments);

  const collapsedSegments = yield select(settingSelectors.collapsedSegment);
  const clashingSegments: Segment[] = util.clashingSegments(allSegments, collapsedSegments);
  for (let i = 0; i < clashingSegments.length; i++) {
    yield put(uncollapseSegment(clashingSegments[i].id));
  }

  yield put(loadAllSegmentsOptimistic(allSegments));
}

function* ProcessSegmentUpdateSaga({ payload }: any): any {
  const { segment } = payload;
  const isWatchModeOn = yield select(settingSelectors.isWatchModeOn);
  const existingSegment = yield select(segmentSelectors.segmentById(segment.id));

  const selectedSegments = yield select(segmentSelectors.selectedSegments);
  const collapsedSegments = yield select(settingSelectors.collapsedSegment);
  const clashingSegments: Segment[] = util.clashingSegments([...selectedSegments, segment], collapsedSegments);
  for (let i = 0; i < clashingSegments.length; i++) {
    yield put(uncollapseSegment(clashingSegments[i].id));
  }

  if (!existingSegment || existingSegment.version < segment.version) {
    yield put(syncSegmentUpdate(segment));
    if (segment.rule && isWatchModeOn) {
      yield put(rerunTestCases());
    }
  }
}

function* ProcessSegmentDeleteSaga({ payload }: any): any {
  const isWatchModeOn = yield select(settingSelectors.isWatchModeOn);
  const segment = yield select(segmentSelectors.segmentById(payload.segment.id));
  if (segment) {
    yield put(syncSegmentDelete(segment.id));
    if (segment.rule && isWatchModeOn) {
      yield put(rerunTestCases());
    }
  }
}

function* activateRuleSaga(): any {
  const activateRuleChan = yield actionChannel(ACTIVATE_RULE);
  let task: any;
  while (true) {
    task = yield fork(determineAtoms);
    yield take(activateRuleChan);
    yield cancel(task);
  }
}

export const segmentSaga = [
  fork(watchMasterAtomList),
  takeEvery(CREATE_NEW_SEGMENT, createSegmentRequestSaga),
  takeLeading(COLLAPSE_ALL_UNSELECTED, collapseAllUnselectedSaga),
  throttle(500, SAVE_RULE, saveRuleSaga),
  takeEvery(LOAD_ALL_SEGMENTS, loadAllSegmentsSaga),
  takeEvery(SAVE_SEGMENT_SELECTION, saveRuleSaga),
  takeEvery(PROCESS_SEGMENT_UPDATE_SUBSCRIPTION, ProcessSegmentUpdateSaga),
  takeEvery(PROCESS_SEGMENT_DELETE_SUBSCRIPTION, ProcessSegmentDeleteSaga),
  takeEvery(EXPORT_RULES, exportRulesSaga),
  takeEvery(EXPORT_XLS, exportXlsSaga),
  takeEvery(COPY_RULES, copyRulesSaga),
  takeEvery(DELETE_SEGMENT, deleteSegmentSaga),
  takeEvery(ACTIVATE_RULE, activateRuleSaga),
  debounce(3000, OPTIMISTIC_SAVE_SEGMENT, runAllTestCases),
];
