// @ts-nocheck
import { Segment } from '../store/segment';
const ExcelJS = require('exceljs');
const he = require('he');
import { isNull, inRange } from 'lodash';

const getIdsInBetween = (startLegalTextId: string, endLegalTextId: string) => {
  const startIndex = toNum(startLegalTextId);
  const endIndex = toNum(endLegalTextId);
  return Array(endIndex - startIndex + 1)
    .fill('')
    .map((_, idx) => 'lt-' + (startIndex + idx));
};

const legalTextsInBetween = (htmlString: string, startLegalTextId: string, endLegalTextId: string) => {
  let legalTexts = '\n';
  const tempEle: any = document.createElement('div');
  tempEle.innerHTML = htmlString;
  let startId = util.toNum(startLegalTextId);
  const endId = util.toNum(endLegalTextId);
  while (endId >= startId) {
    const legalText = tempEle.querySelector('#' + 'lt-' + startId).outerHTML;
    legalTexts = legalTexts
      .concat(he.decode(legalText.replace(/(<[(strong|/strong)|(em|/em)]+>)|(<a.*>.*?<\/a>)/gi, '')))
      .concat('\n')
      .concat('\n');
    startId++;
  }
  return legalTexts;
};

const toNum = (id: string) => +id.split('-')[1];

function prepareForExport(segments: Segment[]): string {
  return segments.reduce((accumulator, { rule }, index) => {
    let result = accumulator;

    if (rule) {
      result += rule;
    } else {
      result += '\n';
    }

    if (index !== segments.length - 1) {
      result += '\n';
    }
    return result;
  }, '');
}

const fireSplitPaneResizeEvent = (size: number) => {
  document.dispatchEvent(
    new CustomEvent('splitPaneResize', {
      detail: { size },
    })
  );
};

const processHtmlTags = (data: any): any => {
  data.forEach((item: any) => {
    item[0] = item[0].includes('lt-table-row') ? handleHtmlTable(item[0]) : handleOtherTags(item[0]);
  });
  return data;
};

const handleHtmlTable = (rows: any): string => {
  const tempEle: any = document.createElement('div');
  tempEle.innerHTML = rows;
  const elementNodes: any = Array.from(tempEle.childNodes).filter((node: any) => node.nodeType === 1);
  return Array.from(elementNodes).reduce((accumulator: string, node: any) => {
    if (node.className === 'lt-table-row') {
      accumulator += '\n------------Table Row Data-----------------------\n';
      node.childNodes.forEach((cell: any) => {
        accumulator += '  |  '.concat(cell.innerText);
      });
    } else {
      accumulator += '\n';
      accumulator += '\n'.concat(node.innerText);
    }
    return accumulator;
  }, '');
};

const handleOtherTags = (rows: any): string => {
  const tempEle: any = document.createElement('div');
  tempEle.innerHTML = rows;
  return tempEle.innerText;
};

async function prepareWorkbook(data: any): Promise<any> {
  const massagedData = processHtmlTags(data);
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Rules');

  worksheet.columns = [{ width: 125 }, { width: 140 }];

  massagedData.forEach((element: any) => {
    worksheet.addRow(element);
  });

  Array.from(Array(worksheet.rowCount + 1)).map((_, rowIndex) => {
    worksheet.getRow(rowIndex).alignment = {
      vertical: 'middle',
      horizontal: 'left',
      wrapText: true,
    };
  });

  worksheet.getColumn(1).font = {
    name: 'Calibri (Body)',
    family: 2,
    size: 11,
  };

  worksheet.getColumn(2).font = {
    name: 'Courier',
    family: 2,
    size: 11,
  };

  const wbout = await workbook.xlsx.writeBuffer();
  return new Blob([wbout], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
}

function convertBinaryToOctet(s: any) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

const isChrome =
  typeof window !== 'undefined' && !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

function isElementVisible(el: any) {
  const rect = el.getBoundingClientRect(),
    vWidth = window.innerWidth || document.documentElement.clientWidth,
    vHeight = window.innerHeight || document.documentElement.clientHeight;

  // Return false if it's not in the viewport
  return !(rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight);
}

const hasCorrectFormat = (token: string): boolean => {
  const pattern = token.match(/(\[([\w\s\d \-.]*(,)*[\w\s\d])*\])/g);
  return !isNull(pattern);
};

const formatItems = (items: string[]): string[] => {
  const formattedItems = items.map((item) => {
    if (item.trim().indexOf(':=') > 0) {
      const splitItem: string[] = item.trim().split(':=');
      item = splitItem[0].trim().concat(':=').concat(splitItem[1].trim());
    }
    if (item.trim().startsWith('[') && hasCorrectFormat(item)) {
      const modalityText: string = item.match(/(\[([\w\s\d \-.]*(,)*[\w\s\d])*\])/g)![0];
      const modalWord: string = modalityText.substring(1, modalityText.length - 1);
      item = item.trim().replace(/(\[([\w\s\d \-.]*(,)*[\w\s\d])*\])/g, '['.concat(modalWord.trim()).concat(']'));
    }

    if (item.trim().match(/~\s*\(/)) {
      const innerText = item.match(/([\w])/g);
      item = '~'.concat(innerText!.join(''));
    }

    return item.trim().startsWith('~') || item.trim().startsWith('[')
      ? item.replace(/(]|~)\s+/g, item.trim().includes('~') ? '~' : ']')
      : item.trim();
  });
  return formattedItems;
};

const getSelectionText = () => {
  let text = '';
  if (window.getSelection) {
    text = window.getSelection().toString();
  } else if (document.selection && document.selection.type != 'Control') {
    text = document.selection.createRange().text;
  }
  return text;
};

const removeTextSelection = () => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    // IE?
    document.selection.empty();
  }
};

const legalTextElFromPoint = (clientY: number) => {
  return document.elementsFromPoint(0, clientY).find((el) => el.id.startsWith('lt-'));
};

const trimWorkspaceAttributes = (workspace: any) => {
  const { ['segments']: val1, ['testCases']: val2, ...trimmedWorkspace } = workspace;
  return trimmedWorkspace;
};

const clashingSegments = (selectedSegment: Segment[], collapsedSegments: Segment[]) => {
  return collapsedSegments.filter((segment) => {
    const collapsedStartId = toNum(segment.startLegalTextId);
    const collapsedEndId = toNum(segment.endLegalTextId);

    const clashedSegment = selectedSegment.find((selectedSegment) => {
      const selectedStartId = toNum(selectedSegment.startLegalTextId);
      const selectedEndId = toNum(selectedSegment.endLegalTextId);
      if (inRange(selectedStartId, collapsedStartId, collapsedEndId)) {
        return true;
      }
      return inRange(selectedEndId, collapsedStartId, collapsedEndId);
    });
    return !!clashedSegment;
  });
};

export const util = {
  toNum,
  getIdsInBetween,
  prepareForExport,
  fireSplitPaneResizeEvent,
  isChrome,
  isElementVisible,
  convertBinaryToOctet,
  processHtmlTags,
  handleHtmlTable,
  handleOtherTags,
  prepareWorkbook,
  legalTextsInBetween,
  formatItems,
  getSelectionText,
  removeTextSelection,
  legalTextElFromPoint,
  trimWorkspaceAttributes,
  clashingSegments,
};
