// @ts-nocheck
import { createSelector } from 'reselect';
import {
  TestCaseState,
  TestCaseStatus,
  SortByType,
  TestCaseStatusWeight,
  TestCase,
  ResultsLineStatus,
} from './reducer';
import { projectSelectors } from '../project';
import * as _ from 'lodash';

const getTestCaseState = (state: any) => state.testCase as TestCaseState;

const activeId = createSelector(getTestCaseState, ({ activeId }) => activeId);

const loading = createSelector(getTestCaseState, ({ loading }) => loading);

const activeTestCase = createSelector(
  getTestCaseState,
  activeId,
  (state: TestCaseState, activeId: string) => state.testCaseMap[activeId] || {}
);

const facts = createSelector(activeTestCase, (testCase: TestCase) => testCase.facts || '');

const factsById = (id: string) => createSelector(getTestCaseState, ({ testCaseMap }) => testCaseMap[id].facts || '');

const title = createSelector(activeTestCase, (testCase: TestCase) => testCase.title);

const description = createSelector(activeTestCase, (testCase: TestCase) => testCase.description);

const requiredResults = createSelector(activeTestCase, (testCase: TestCase) => testCase.requiredResults);

const unwantedResults = createSelector(activeTestCase, (testCase: TestCase) => testCase.unwantedResults);

const results = createSelector(activeTestCase, (testCase: TestCase) => testCase.results);

const isRunning = createSelector(activeTestCase, ({ status }) => status === TestCaseStatus.RUNNING);

const requiredResultsStatus = createSelector(activeTestCase, (testCase: TestCase) => testCase.requiredResultsStatus);

const requiredResultsOverallStatus = createSelector(requiredResultsStatus, (status: ResultsLineStatus[]) =>
  findOverallStatus(status)
);

const unwantedResultsStatus = createSelector(activeTestCase, (testCase: TestCase) => testCase.unwantedResultsStatus);

const unwantedResultsOverallStatus = createSelector(unwantedResultsStatus, (status: ResultsLineStatus[]) =>
  findOverallStatus(status)
);

const resultsLineStatus = createSelector(activeTestCase, (testCase: TestCase) => testCase.resultsLineStatus);

function findOverallStatus(status: ResultsLineStatus[]): ResultsLineStatus {
  if (_.isNil(status)) {
    return ResultsLineStatus.NA;
  }
  if (status.find((i) => i === ResultsLineStatus.FAIL_UNWANTED)) {
    return ResultsLineStatus.FAIL_UNWANTED;
  }
  if (status.find((i) => i === ResultsLineStatus.FAIL_REQUIRED)) {
    return ResultsLineStatus.FAIL_REQUIRED;
  }
  if (status.find((i) => i === ResultsLineStatus.SUCCESS_UNWANTED)) {
    return ResultsLineStatus.SUCCESS_UNWANTED;
  }
  if (status.find((i) => i === ResultsLineStatus.SUCCESS_REQUIRED)) {
    return ResultsLineStatus.SUCCESS_REQUIRED;
  }
  return ResultsLineStatus.NA;
}

const sortByType = createSelector(getTestCaseState, ({ sortByType }) => sortByType);

const allTestCases = createSelector(getTestCaseState, sortByType, ({ testCaseMap }, sortByType: SortByType) =>
  Object.keys(testCaseMap)
    .map((id) => testCaseMap[id])
    .sort((a, b) => {
      if (sortByType === SortByType.TITLE) {
        return compareTitle(a.title, b.title);
      }
      if (sortByType === SortByType.STATUS) {
        return compareStatus(a.status, b.status);
      }
      return 0;
    })
);

const allSavedTestCasesForProject = (projectId?: string) => {
  return createSelector(
    getTestCaseState,
    sortByType,
    projectSelectors.parentProjectId,
    ({ testCaseMap }, sortByType: SortByType) =>
      Object.keys(testCaseMap)
        .filter((id) => id !== 'draft')
        .map((id) => testCaseMap[id])
        .sort((a, b) => {
          if (sortByType === SortByType.TITLE) {
            return compareTitle(a.title, b.title);
          }
          if (sortByType === SortByType.STATUS) {
            return compareStatus(a.status, b.status);
          }
          return 0;
        })
  );
};

function compareTitle(a: string, b: string) {
  const titleA = (a && a.toUpperCase()) || '';
  const titleB = (b && b.toUpperCase()) || '';

  if (titleA < titleB) {
    return -1;
  }
  if (titleA > titleB) {
    return 1;
  }
  return 0;
}

function compareStatus(statusA: TestCaseStatus, statusB: TestCaseStatus) {
  if (statusA === statusB) {
    return 0;
  }
  return TestCaseStatusWeight[statusA] - TestCaseStatusWeight[statusB];
}

const status = createSelector(activeTestCase, (testCase: TestCase) => testCase.status);

const statusById = (id: string) => createSelector(getTestCaseState, ({ testCaseMap }) => testCaseMap[id].status);

const testCaseById = (id: string) => createSelector(getTestCaseState, ({ testCaseMap }) => testCaseMap[id]);

const testCasesWithNilStatus = createSelector(allTestCases, (testCases: TestCase[]) =>
  testCases.filter((item) => item.status === TestCaseStatus.NA)
);

export const testCaseSelectors = {
  activeId,
  activeTestCase,
  title,
  description,
  facts,
  factsById,
  results,
  isRunning,
  requiredResults,
  unwantedResults,
  requiredResultsStatus,
  unwantedResultsStatus,
  requiredResultsOverallStatus,
  unwantedResultsOverallStatus,
  resultsLineStatus,
  allSavedTestCasesForProject,
  allTestCases,
  testCasesWithNilStatus,
  status,
  statusById,
  testCaseById,
  sortByType,
  loading,
};
