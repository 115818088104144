import {
  select,
  put,
  debounce,
  actionChannel,
  take,
  fork,
  cancel,
  race,
  call,
  delay,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import {
  gridItemsUpdated,
  UPDATE_RULE_BOX_HEIGHT,
  updateSnapGutterHeight,
  START_SCROLL_OBSERVER,
  STOP_SCROLL_OBSERVER,
  segmentInView,
  segmentOutOfView,
  RESTART_SCROLL_OBSERVER,
  SNAP_SEGMENT_TO_RULE,
  snapSegmentToRule,
  updateRuleBoxHeight,
  STACK_RULES_IN_ORDER,
  stackRulesInOrder,
  snapRuleToSegment,
  SNAP_RULE_TO_SEGMENT,
  SNAP_BEST_POSSIBLE_RULE,
  snapBestPossibleRule,
  SEGMENT_RULE_PANE_SCROLLED,
  activeRuleVisibility,
  rulesPanelHeight,
} from './actions';
import {
  segmentSelectors,
  Segment,
  ACTIVATE_SEGMENT,
  ACTIVATE_RULE,
  OPTIMISTIC_CREATE_SEGMENT,
  CLEAR_ACTIVE_SEGMENT,
  OPTIMISTIC_DELETE_SEGMENT,
  clearActiveSegment,
} from '../segment';
import { gridSelectors } from './selectors';
import { eventChannel, EventChannel } from 'redux-saga';
import { settingSelectors } from '../setting';
import { GridState } from './reducer';
import { util } from '../../services';

function segmentObserverChannel() {
  return eventChannel((emitter) => {
    const observingElements: any[] = [];
    const config = {
      root: document.getElementById('segment-panel'),
      rootMargin: '40px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const { intersectionRatio, target } = entry;
        if (intersectionRatio >= config.threshold) {
          emitter({ type: 'InView', segmentId: target.id });
        } else {
          emitter({ type: 'OutOfView', segmentId: target.id });
        }
      });
    }, config);

    const domElements = document.querySelectorAll('.segment-resizable');
    domElements.forEach((domElem) => {
      observer.observe(domElem);
      observingElements.push(domElem);
    });

    return () => {
      observingElements.forEach((domEl) => observer.unobserve(domEl));
    };
  });
}

function* observeSegmentsVisibility() {
  const channel: EventChannel<any> = yield call(segmentObserverChannel);
  try {
    while (true) {
      const { type, segmentId } = yield take(channel);
      if (type === 'InView') {
        yield put(segmentInView(segmentId));
      } else if (type === 'OutOfView') {
        yield put(segmentOutOfView(segmentId));
      }
    }
  } finally {
    channel.close();
  }
}

function* watchScrollObserver(): any {
  const startChan = yield actionChannel(START_SCROLL_OBSERVER);
  const stopChan = yield actionChannel(STOP_SCROLL_OBSERVER);
  const restartChan = yield actionChannel(RESTART_SCROLL_OBSERVER);
  let task: any;

  while (true) {
    const { stopAction, startAction, restartAction } = yield race({
      startAction: take(startChan),
      stopAction: take(stopChan),
      restartAction: take(restartChan),
    });

    if (stopAction || restartAction) {
      if (task) {
        yield cancel(task);
      }
    }

    if (startAction || restartAction) {
      task = yield fork(observeSegmentsVisibility);
    }
  }
}

function activeRuleObserverChannel(activeSegmentId: string) {
  return eventChannel((emitter) => {
    const config = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const { isIntersecting, intersectionRatio } = entry;
        if (isIntersecting || intersectionRatio >= 1) {
          emitter({ type: 'InView' });
        } else {
          emitter({ type: 'OutOfView' });
        }
      });
    }, config);

    const element = document.getElementById(`rule-${activeSegmentId}`);
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  });
}

function* observeActiveRuleVisibility(): any {
  yield delay(100);
  const activeSegmentId = yield select(segmentSelectors.activeSegmentId);
  const channel: EventChannel<any> = yield call(activeRuleObserverChannel, activeSegmentId);
  try {
    while (true) {
      const { type } = yield take(channel);
      if (type === 'InView') {
        yield put(activeRuleVisibility(true));
      } else if (type === 'OutOfView') {
        yield put(activeRuleVisibility(false));
      }
    }
  } finally {
    channel.close();
  }
}

function* watchActiveRuleObserver(): any {
  const activateRuleChan = yield actionChannel(ACTIVATE_RULE);
  const activateSegmentChan = yield actionChannel(ACTIVATE_SEGMENT);
  const clearChan = yield actionChannel(CLEAR_ACTIVE_SEGMENT);
  const createChan = yield actionChannel(OPTIMISTIC_CREATE_SEGMENT);

  let task: any;

  while (true) {
    const { activateRuleAction, activateSegmentAction, createAction } = yield race({
      activateRuleAction: take(activateRuleChan),
      activateSegmentAction: take(activateSegmentChan),
      clearAction: take(clearChan),
      createAction: take(createChan),
    });

    if (task) {
      yield cancel(task);
    }

    if (activateRuleAction || activateSegmentAction || createAction) {
      task = yield fork(observeActiveRuleVisibility);
    }
  }
}

function* waitForRulesToRender(): any {
  yield delay(100);
  const updateHeightChan = yield actionChannel(UPDATE_RULE_BOX_HEIGHT);
  let gridMap = yield select(gridSelectors.gridMap);
  let segments: Segment[] = yield select(segmentSelectors.selectedSegments);

  while (Object.keys(gridMap).length < segments.length) {
    yield take(updateHeightChan);
    gridMap = yield select(gridSelectors.gridMap);
    segments = yield select(segmentSelectors.selectedSegments);
  }
}

function* alignTopLevelRules(segments: Segment[], newGridMap: GridState['gridMap'], segmentToSnap: Segment): any {
  const snapHeight = yield select(gridSelectors.snapGutterHeight);
  for (let i = segments.length - 1; i >= 0; i--) {
    const segment = segments[i];
    const nextSegment = segments[i + 1] || segmentToSnap;

    const legalTextEl = document.getElementById(segment.startLegalTextId);
    let top = legalTextEl ? legalTextEl.offsetTop : 0;
    if (snapHeight > 0) {
      top += snapHeight;
    }

    const bottom = top + (newGridMap[segment.id].height || 30);
    const nextSegmentTop = newGridMap[nextSegment.id].top;

    if (bottom + 10 >= nextSegmentTop) {
      top = nextSegmentTop - 10 - (newGridMap[segment.id].height || 30);
    }

    newGridMap[segment.id] = {
      ...newGridMap[segment.id],
      top,
    };
  }
}

function* alignBottomLevelRules(segments: Segment[], newGridMap: GridState['gridMap'], segmentToSnap: Segment): any {
  const snapHeight = yield select(gridSelectors.snapGutterHeight);
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    const prevSegment = segments[i - 1] || segmentToSnap;

    const legalTextEl = document.getElementById(segment.startLegalTextId);
    let top = legalTextEl ? legalTextEl.offsetTop : 0;
    if (snapHeight > 0) {
      top += snapHeight;
    }

    const prevSegmentBottom = newGridMap[prevSegment.id].top + (newGridMap[prevSegment.id].height || 30);

    if (prevSegmentBottom + 10 >= top) {
      top = prevSegmentBottom + 10;
    }

    newGridMap[segment.id] = {
      ...newGridMap[segment.id],
      top,
    };
  }
}

function* alignRule(segmentToSnap: Segment, newGridMap: GridState['gridMap']): any {
  const legalTextEl = document.getElementById(segmentToSnap && segmentToSnap.startLegalTextId);
  let top = legalTextEl ? legalTextEl.offsetTop : 0;

  const snapHeight = yield select(gridSelectors.snapGutterHeight);
  if (snapHeight > 0) {
    top += snapHeight;
  }

  newGridMap[segmentToSnap.id] = {
    ...newGridMap[segmentToSnap.id],
    top,
  };
}

function* numberLines(segments: Segment[], newGridMap: GridState['gridMap']) {
  for (let i = 0; i < segments.length; i++) {
    let firstLineNumber = 1;
    const segment = segments[i];
    const prevSegment = segments[i - 1];
    if (prevSegment) {
      const prevLineCount = prevSegment.rule ? prevSegment.rule.split(/\n/).length : 1;
      firstLineNumber = newGridMap[prevSegment.id].firstLineNumber + prevLineCount;
    }
    newGridMap[segment.id] = {
      ...newGridMap[segment.id],
      firstLineNumber,
    };
  }
}

function* snapRuleToSegmentSaga({ payload }: any): any {
  const { segmentId: segmentIdToSnap } = payload;
  const segmentToSnap = yield select(segmentSelectors.segmentById(segmentIdToSnap));

  let gridMap = yield select(gridSelectors.gridMap);
  const segments: Segment[] = yield select(segmentSelectors.selectedSegments);

  yield waitForRulesToRender();
  gridMap = yield select(gridSelectors.gridMap);

  const newGridMap = { ...gridMap } as any;

  const snapSegmentIndex = segments.findIndex((s) => s.id === segmentIdToSnap);

  let topLevelRules: Segment[] = [];
  if (snapSegmentIndex > 0) {
    topLevelRules = segments.slice(0, snapSegmentIndex);
  }

  let bottomLevelRules: Segment[] = [];
  if (snapSegmentIndex < segments.length - 1) {
    bottomLevelRules = segments.slice(snapSegmentIndex + 1);
  }

  yield call(alignRule, segmentToSnap, newGridMap);
  yield call(alignTopLevelRules, topLevelRules, newGridMap, segmentToSnap);
  yield call(alignBottomLevelRules, bottomLevelRules, newGridMap, segmentToSnap);

  yield call(numberLines, segments, newGridMap);
  yield put(gridItemsUpdated(newGridMap));

  const currentRulesPanelHeight = yield select(gridSelectors.rulesPanelHeight);
  const lastBottomPosition = yield select(gridSelectors.lastBottomPosition);
  if (lastBottomPosition > currentRulesPanelHeight) {
    yield put(rulesPanelHeight(lastBottomPosition));
  }
}

function* stackRulesInOrderSaga(): any {
  let gridMap = yield select(gridSelectors.gridMap);
  const segments: Segment[] = yield select(segmentSelectors.selectedSegments);

  yield waitForRulesToRender();
  gridMap = yield select(gridSelectors.gridMap);

  const newGridMap = { ...gridMap } as any;

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    const prevSegment = segments[i - 1];
    let top = 0;

    if (prevSegment) {
      const prevSegmentBottom = newGridMap[prevSegment.id].top + (newGridMap[prevSegment.id].height || 30);

      top = prevSegmentBottom + 10;
    }

    newGridMap[segment.id] = {
      ...newGridMap[segment.id],
      top,
    };
  }

  yield call(numberLines, segments, newGridMap);
  yield put(gridItemsUpdated(newGridMap));

  const lastBottomPosition = yield select(gridSelectors.lastBottomPosition);
  yield put(rulesPanelHeight(lastBottomPosition));
}

function* updateHeightSaga(): any {
  const isSegmentPanelClosed = yield select(settingSelectors.isSegmentPanelClosed);
  if (isSegmentPanelClosed) {
    yield put(stackRulesInOrder());
  } else {
    yield put(snapBestPossibleRule());
  }
}

function* activateRuleSaga(): any {
  const isSegmentPanelClosed = yield select(settingSelectors.isSegmentPanelClosed);
  if (isSegmentPanelClosed) {
    return;
  }
  yield put(snapSegmentToRule());
}

function* snapSegmentToRuleSaga(): any {
  const activeSegmentId = yield select(segmentSelectors.activeSegmentId);
  const firstSegmentId = yield select(segmentSelectors.firstSelectedSegmentId);
  const currentGutterHeight = yield select(gridSelectors.snapGutterHeight);
  if (firstSegmentId === activeSegmentId && currentGutterHeight > 0) {
    yield put(updateSnapGutterHeight(0));
    yield put(snapRuleToSegment(firstSegmentId));
    setTimeout(() => {
      const element = document.getElementById(`rule-${firstSegmentId}`);
      if (!util.isElementVisible(element)) {
        element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }, 200);
    return;
  }

  const gridMap = yield select(gridSelectors.gridMap);
  const ruleTop = gridMap[activeSegmentId].top;
  const segmentTop = document.getElementById(activeSegmentId)!.offsetTop;
  const gutterHeight = ruleTop - segmentTop;
  if (gutterHeight >= 0) {
    if (gutterHeight !== currentGutterHeight) {
      yield put(updateSnapGutterHeight(gutterHeight));
    }
  } else {
    yield put(updateSnapGutterHeight(0));
    yield delay(100);
    yield put(snapRuleToSegment(activeSegmentId));
  }
}

function* createSegmentActivateSaga({ payload }: any): any {
  const { segment, type } = payload;
  if (type === 'selected') {
    const gridDetails = yield select(gridSelectors.gridById(segment.id));
    if (gridDetails && !gridDetails.height) {
      yield put(updateRuleBoxHeight(segment.id, 30));
    }
  }
}

function* activateSegmentSaga(): any {
  const activeId = yield select(segmentSelectors.activeSegmentId);
  const firstSegmentId = yield select(segmentSelectors.firstSelectedSegmentId);
  if (activeId === firstSegmentId) {
    yield put(updateSnapGutterHeight(0));
    setTimeout(() => {
      const element = document.getElementById(`${activeId}`);
      if (!util.isElementVisible(element)) {
        element!.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }, 300);
  }
  yield put(snapRuleToSegment(activeId));
}

function* snapBestPossibleRuleSaga(): any {
  const segmentPanelClosed = yield select(settingSelectors.isSegmentPanelClosed);
  if (segmentPanelClosed) {
    yield put(stackRulesInOrder());
    return;
  }

  const activeSegmentId = yield select(segmentSelectors.activeSegmentId);
  const firstSegmentId = yield select(segmentSelectors.firstSelectedSegmentId);
  const firstVisibleSegment = yield select(gridSelectors.firstVisibleSegment);
  const isActiveSegmentVisible = yield select(gridSelectors.isActiveSegmentVisible);
  const snapGutterHeight = yield select(gridSelectors.snapGutterHeight);

  if (isActiveSegmentVisible) {
    yield put(snapRuleToSegment(activeSegmentId));
    return;
  }

  const isActiveRuleVisible = yield select(gridSelectors.isActiveRuleVisible);
  if (isActiveRuleVisible) {
    yield put(snapRuleToSegment(activeSegmentId));
    return;
  }

  if (firstVisibleSegment) {
    yield put(snapRuleToSegment(firstVisibleSegment.id));
    return;
  }

  if (firstSegmentId) {
    if (snapGutterHeight > 0) {
      yield put(updateSnapGutterHeight(0));
      yield delay(100);
    }
    yield put(snapRuleToSegment(firstSegmentId));
    return;
  }
}

function* snapOnScrollingSaga(): any {
  const autoScroll = yield select(settingSelectors.autoScroll);
  if (!autoScroll) {
    return;
  }

  const segmentPanelClosed = yield select(settingSelectors.isSegmentPanelClosed);
  if (segmentPanelClosed) {
    return;
  }

  const activeSegmentId = yield select(segmentSelectors.activeSegmentId);
  const isActiveRuleVisible = yield select(gridSelectors.isActiveRuleVisible);
  const isActiveSegmentVisible = yield select(gridSelectors.isActiveSegmentVisible);
  if (isActiveRuleVisible || isActiveSegmentVisible) {
    yield put(snapRuleToSegment(activeSegmentId));
    return;
  }

  const firstVisibleSegment = yield select(gridSelectors.firstVisibleSegment);
  const firstSegmentId = yield select(segmentSelectors.firstSelectedSegmentId);
  const gutterHeight = yield select(gridSelectors.snapGutterHeight);
  if (firstVisibleSegment && firstVisibleSegment.id === firstSegmentId && gutterHeight > 0) {
    yield put(updateSnapGutterHeight(0));
    yield put(snapRuleToSegment(firstVisibleSegment.id));
    setTimeout(() => {
      const element = document.getElementById(`${firstVisibleSegment.id}`);
      if (!util.isElementVisible(element)) {
        element!.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }, 200);
    return;
  }

  if (firstVisibleSegment) {
    yield put(snapRuleToSegment(firstVisibleSegment.id));
    return;
  }
}

function* deleteSegmentSaga(): any {
  const activeSegmentId = yield select(segmentSelectors.activeSegmentId);
  if (!activeSegmentId) {
    yield put(clearActiveSegment());
  }
}

function* clearActiveSegmentSaga(): any {
  const autoScroll = yield select(settingSelectors.autoScroll);
  const isSegmentPanelClosed = yield select(settingSelectors.isSegmentPanelClosed);

  if (autoScroll && !isSegmentPanelClosed) {
    yield put(snapBestPossibleRule());
  }
}

export const gridSaga = [
  fork(watchScrollObserver),
  fork(watchActiveRuleObserver),
  takeLatest(STACK_RULES_IN_ORDER, stackRulesInOrderSaga),
  debounce(200, UPDATE_RULE_BOX_HEIGHT, updateHeightSaga),
  takeLatest(ACTIVATE_SEGMENT, activateSegmentSaga),
  takeLatest(SNAP_SEGMENT_TO_RULE, snapSegmentToRuleSaga),
  takeLatest(ACTIVATE_RULE, activateRuleSaga),
  takeLatest(SNAP_RULE_TO_SEGMENT, snapRuleToSegmentSaga),
  takeEvery(OPTIMISTIC_CREATE_SEGMENT, createSegmentActivateSaga),
  debounce(200, SNAP_BEST_POSSIBLE_RULE, snapBestPossibleRuleSaga),
  takeLatest(SEGMENT_RULE_PANE_SCROLLED, snapOnScrollingSaga),
  takeEvery(OPTIMISTIC_DELETE_SEGMENT, deleteSegmentSaga),
  takeLeading(CLEAR_ACTIVE_SEGMENT, clearActiveSegmentSaga),
];
