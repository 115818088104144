import { takeEvery, fork, actionChannel, cancel, cancelled, race, delay, spawn, throttle } from 'redux-saga/effects';
import {
  RUN_TEST_CASE,
  updateResults,
  SAVE_FACTS,
  SAVE_UNWANTED_RESULTS,
  SAVE_REQUIRED_RESULTS,
  SAVE_DESCRIPTION,
  SAVE_TITLE,
  DELETE_TEST_CASE,
  deleteTestCaseOptimistic,
  deleteTestCaseSuccess,
  CLONE_TEST_CASE,
  cloneTestCaseOptimistic,
  cloneTestCaseSuccess,
  CREATE_NEW_TEST_CASE,
  syncTestCaseUpdate,
  syncTestCaseDelete,
  activateTestCase,
  START_WATCH_MODE,
  STOP_WATCH_MODE,
  updateStatus,
  RERUN_TEST_CASES,
  CLONE_TEST_CASE_OPTIMISTIC,
  SYNC_TEST_CASE_UPDATE,
  CREATE_NEW_TEST_CASE_OPTIMISTIC,
  TEST_CASE_PANEL_LOADED,
  startWatchMode,
  sortTestCases,
  loadAllTestcasesOptimistic,
  COPY_TEST_CASE,
  LOAD_ALL_TESTCASES,
  apiError,
  PROCESS_TEST_CASE_UPDATE_SUBSCRIPTION,
  PROCESS_TEST_CASE_DELETE_SUBSCRIPTION,
  LOAD_ALL_TESTCASES_OPTIMISTIC,
  stopWatchMode,
  createNewTestCaseOptimistic,
  createNewTestCaseSuccess,
} from './actions';
import * as _ from 'lodash';
import { util, testCaseService as service } from '../../services';
import { put, select, call, take } from 'redux-saga/effects';
import { segmentSelectors, Segment } from '../segment';
import { testCaseSelectors } from './selectors';
import { eventChannel, EventChannel } from 'redux-saga';
import { TestCase, initTestCase, TestCaseStatus, ResultsLineStatus } from './reducer';
import { settingSelectors, setTestCaseSetting, deleteTestCaseSettings, openResultsPanel } from '../setting';
import { projectSelectors } from '../project';
import TurnipWorker from 'worker-loader!../../workers/turnip.worker';

const ObjectID = require('bson-objectid');

export interface RuleObject {
  rules: string;
  source: string;
}
export interface RuleObjects {
  rule_collection: RuleObject[];
  input: string;
}

function createWorkerChannel(worker: TurnipWorker) {
  return eventChannel((emitter) => {
    const messageListener = ({ data }: any) => emitter(data);
    worker.addEventListener('message', messageListener);

    const errorListener = (error: ErrorEvent) => {
      console.error(error);
    };
    worker.addEventListener('error', errorListener);

    return () => {
      worker.removeEventListener('message', messageListener);
      worker.removeEventListener('error', errorListener);
    };
  });
}

function validateExpectedResults(results: string, expectedResults: string, type: string): ResultsLineStatus[] {
  if (!expectedResults || !expectedResults.trim() || _.isNil(results) || results.includes('errors')) {
    return [];
  }

  const expectedLines = util.formatItems(expectedResults.split(/\r?\n/));

  const resultLines = util.formatItems(results.trim().split(/\r?\n/));

  const status: ResultsLineStatus[] = [];
  for (let i = 0; i < expectedLines.length; i++) {
    const line = expectedLines[i];
    if (!line.trim()) {
      status.push(ResultsLineStatus.NA);
      continue;
    }
    if (type === 'required') {
      if (line.trim().startsWith('//')) {
        status.push(ResultsLineStatus.NA);
        continue;
      }
      resultLines.includes(line.trim())
        ? status.push(ResultsLineStatus.SUCCESS_REQUIRED)
        : status.push(ResultsLineStatus.FAIL_REQUIRED);
    } else {
      if (line.trim().startsWith('//')) {
        status.push(ResultsLineStatus.NA);
        continue;
      }
      resultLines.includes(line.trim())
        ? status.push(ResultsLineStatus.FAIL_UNWANTED)
        : status.push(ResultsLineStatus.SUCCESS_UNWANTED);
    }
  }

  return status;
}

function getResultsLineStatus(results: string, requiredResults: string, unwantedResults: string): ResultsLineStatus[] {
  if (_.isNil(results) || results.includes('errors')) {
    return [];
  }
  const resultLines = util.formatItems(results.trim().split(/\r?\n/));
  const resultsLineStatus: ResultsLineStatus[] = resultLines && Array(resultLines.length).fill(TestCaseStatus.NA);

  if (!_.isNil(requiredResults) && requiredResults && requiredResults.trim()) {
    const requiredResultsLines = util.formatItems(requiredResults.trim().split(/\r?\n/));
    resultLines.forEach((resultsLine: string, index: number) => {
      requiredResultsLines.forEach((expectedLine: string) => {
        if (resultsLine === expectedLine) {
          resultsLineStatus[index] = ResultsLineStatus.SUCCESS_REQUIRED;
        }
      });
    });
  }

  if (!_.isNil(unwantedResults) && unwantedResults && unwantedResults.trim()) {
    const unwantedResultsLines = util.formatItems(unwantedResults.trim().split(/\r?\n/));

    resultLines.forEach((resultsLine: string, index: number) => {
      unwantedResultsLines.forEach((expectedLine: string) => {
        if (resultsLine.trim() === expectedLine.trim()) {
          resultsLineStatus[index] = ResultsLineStatus.FAIL_UNWANTED;
        }
      });
    });
  }

  return resultsLineStatus;
}

function* listernForResults(channel: EventChannel<any>): any {
  const { testCaseId, results, error } = yield take(channel);
  const testCase = yield select(testCaseSelectors.testCaseById(testCaseId));
  console.log(`......result ..${JSON.stringify(results)}`);
  if (!testCase) {
    return;
  }

  if (error) {
    throw error;
  }

  const requiredResultsStatus = validateExpectedResults(results.result, testCase.requiredResults, 'required');
  const unwantedResultsStatus = validateExpectedResults(results.result, testCase.unwantedResults, 'unwanted');

  const resultsLineStatus = getResultsLineStatus(results.result, testCase.requiredResults, testCase.unwantedResults);

  const hasError =
    (results.errors && results.errors.length > 0) ||
    [...requiredResultsStatus, ...unwantedResultsStatus].find(
      (lineStatus: ResultsLineStatus) =>
        lineStatus === ResultsLineStatus.FAIL_UNWANTED || lineStatus === ResultsLineStatus.FAIL_REQUIRED
    );

  yield put(
    updateResults(
      testCaseId,
      results.result || (results.errors && results.errors.join()) || '',
      hasError ? TestCaseStatus.FAIL : TestCaseStatus.SUCCESS,
      requiredResultsStatus,
      unwantedResultsStatus,
      resultsLineStatus
    )
  );
}

function* postMessage(testCaseId: string, rules: RuleObject[], worker: TurnipWorker): any {
  yield delay(100);
  const fact = yield select(testCaseSelectors.factsById(testCaseId));
  const ruleCollection: RuleObjects = {
    rule_collection: rules,
    input: fact.trim(),
  };
  console.log(`rules::: ${JSON.stringify(ruleCollection)}`);
  worker.postMessage({
    testCaseId,
    ruleCollection,
  });
}

function* retryRunTestCase(
  testCaseId: string,
  rules: RuleObject[],
  channel: EventChannel<any>,
  turnipWorker: TurnipWorker
): any {
  let retryCount = 3;
  let turnipError;
  while (retryCount) {
    try {
      yield fork(postMessage, testCaseId, rules, turnipWorker);
      yield listernForResults(channel);
      return;
    } catch (error) {
      yield delay(100);
      retryCount--;
      turnipError = error;
    }
  }

  yield put(updateResults(testCaseId, turnipError, TestCaseStatus.FAIL, [], [], []));
}

function* watchRunSingleTestCase(): any {
  const requestChan = yield actionChannel(RUN_TEST_CASE);

  let turnipWorker: TurnipWorker;
  if (typeof window !== 'undefined' && typeof window.Worker !== 'undefined') {
    turnipWorker = new TurnipWorker();
  } else {
    throw 'Browser not supported...';
  }

  const channel: EventChannel<any> = yield call(createWorkerChannel, turnipWorker);

  while (true) {
    const {
      payload: { testCaseId },
    } = yield take(requestChan);

    const ruleList: RuleObject[] = [];
    const projectTreeRootId = yield select(projectSelectors.projectTreeRootId);
    const rootProject = yield select(projectSelectors.projectById(projectTreeRootId));
    const workspaceMetaDetails: any = yield select(projectSelectors.allWorkspaceIdName(rootProject));
    const flatWorkspacesMetaDetails: any =
      (workspaceMetaDetails && workspaceMetaDetails.length > 0 && _.flattenDeep(workspaceMetaDetails)) || [];

    for (let i = 0; i < flatWorkspacesMetaDetails.length; i++) {
      const segmentArray = yield select(
        segmentSelectors.allSelectedSegmentsByWorkspaceId(flatWorkspacesMetaDetails[i].id)
      );
      const ruleObject: RuleObject = {
        rules: getRulesFromSegment(segmentArray),
        source: flatWorkspacesMetaDetails[i].name,
      };
      ruleList.push(ruleObject);
    }
    yield retryRunTestCase(testCaseId, ruleList, channel, turnipWorker);
  }
}

let turnipWorker: TurnipWorker;
function* runAllNilTestCasesInBackground(delaySec?: number): any {
  if (delaySec) {
    yield delay(500);
  }
  if (typeof window !== 'undefined' && typeof window.Worker !== 'undefined') {
    if (!turnipWorker) {
      turnipWorker = new TurnipWorker();
    }
  } else {
    throw 'Browser not supported...';
  }

  const channel: EventChannel<any> = yield call(createWorkerChannel, turnipWorker);
  try {
    const ruleList: RuleObject[] = [];
    const projectTreeRootId = yield select(projectSelectors.projectTreeRootId);
    const rootProject = yield select(projectSelectors.projectById(projectTreeRootId));
    const workspaceMetaDetails: any = yield select(projectSelectors.allWorkspaceIdName(rootProject));
    const flatWorkspacesMetaDetails: any =
      (typeof workspaceMetaDetails !== 'undefined' &&
        workspaceMetaDetails.length > 0 &&
        _.flattenDeep(workspaceMetaDetails)) ||
      [];
    for (let i = 0; i < flatWorkspacesMetaDetails.length; i++) {
      const segmentArray = yield select(
        segmentSelectors.allSelectedSegmentsByWorkspaceId(flatWorkspacesMetaDetails[i].id)
      );
      const ruleObject: RuleObject = {
        rules: getRulesFromSegment(segmentArray),
        source: flatWorkspacesMetaDetails[i].name,
      };
      ruleList.push(ruleObject);
    }

    const testCasesToRun = yield select(testCaseSelectors.testCasesWithNilStatus);
    const testActiveId = yield select(testCaseSelectors.activeId);
    for (let i = 0; i < testCasesToRun.length; i++) {
      const testCaseId = testCasesToRun[i].id.toString();
      try {
        const testCase = yield select(testCaseSelectors.testCaseById(testCaseId));
        if (!testCase) {
          continue;
        }

        yield put(updateStatus(testCaseId, TestCaseStatus.RUNNING));
        yield retryRunTestCase(testCaseId, ruleList, channel, turnipWorker);

        if (testActiveId === testCaseId) {
          yield put(openResultsPanel());
        }
      } finally {
        if (yield cancelled()) {
          yield put(updateStatus(testCaseId, TestCaseStatus.NA));
        }
      }
    }
  } finally {
    channel.close();
  }
}

export const getRulesFromSegment = (segments: Segment[]) => {
  return segments.reduce((accumulator, { rule }) => {
    let result = accumulator;
    if (!_.isNil(rule)) {
      result += rule + '\n';
    }
    return result;
  }, '');
};

function* watchRunAllMode(): any {
  const startChan = yield actionChannel(START_WATCH_MODE);

  while (true) {
    yield take(startChan);
    const stopChan = yield actionChannel(STOP_WATCH_MODE);
    const rerunChan = yield actionChannel(RERUN_TEST_CASES);
    const cloneChan = yield actionChannel(CLONE_TEST_CASE_OPTIMISTIC);
    const syncChan = yield actionChannel(SYNC_TEST_CASE_UPDATE);
    const addChan = yield actionChannel(CREATE_NEW_TEST_CASE_OPTIMISTIC);
    const factsChan = yield actionChannel(SAVE_FACTS);
    const unwantedResultsChan = yield actionChannel(SAVE_UNWANTED_RESULTS);
    const requiredResultsChan = yield actionChannel(SAVE_REQUIRED_RESULTS);

    while (true) {
      const bgTask = yield fork(runAllNilTestCasesInBackground, 1000);
      const { stopAction } = yield race({
        stopAction: take(stopChan),
        rerunAction: take(rerunChan),
        cloneAction: take(cloneChan),
        syncAction: take(syncChan),
        addAction: take(addChan),
        factsAction: take(factsChan),
        unwantedResultsAction: take(unwantedResultsChan),
        requiredResultsAction: take(requiredResultsChan),
      });
      yield cancel(bgTask);
      if (stopAction) {
        break;
      }
    }
  }
}

function* saveInputSaga(): any {
  // @ts-ignore
  const activeTestCase = yield select(testCaseSelectors.activeTestCase);
  yield call(service.updateTestCase, activeTestCase);
}

function* createTestCaseSaga(): any {
  // @ts-ignore
  const activeTestCase = yield select(testCaseSelectors.activeTestCase);
  const rootProjectId = yield select(projectSelectors.projectTreeRootId);
  const activeWorkspaceId = yield select(projectSelectors.activeWorkspaceId);
  const activeTestCaseSetting = yield select(settingSelectors.testCaseSettingById(activeTestCase.id));
  const newTestCase = {
    id: ObjectID().toString(),
    projectId: rootProjectId,
    workspaceId: activeWorkspaceId,
    version: 1,
  } as TestCase;
  yield put(
    setTestCaseSetting(newTestCase.id, {
      ...activeTestCaseSetting,
    })
  );

  yield put(createNewTestCaseOptimistic(newTestCase));
  const data = yield call(service.createTestCase, newTestCase);
  yield put(createNewTestCaseSuccess(data));
}

function* copyTestCaseSaga({ payload }: any): any {
  const { testcaseId, projectId, workspaceId } = payload;
  const testcase = yield select(testCaseSelectors.testCaseById(testcaseId));
  const newTestCase = {
    ...testcase,
    id: ObjectID().toString(),
    projectId: projectId,
    workspaceId: workspaceId,
    version: 1,
  } as TestCase;
  yield call(service.createTestCase, newTestCase);
}

function* cloneTestCaseSaga(): any {
  // @ts-ignore
  const activeTestCase = yield select(testCaseSelectors.activeTestCase);
  const activeTestCaseSetting = yield select(settingSelectors.testCaseSettingById(activeTestCase.id));

  const newTestCase = {
    ...initTestCase,
    id: ObjectID().toString(),
    version: 1,
    title: (activeTestCase.title && `Clone of ${activeTestCase.title}`) || '',
    description: activeTestCase.description,
    facts: activeTestCase.facts,
    requiredResults: activeTestCase.requiredResults,
    unwantedResults: activeTestCase.unwantedResults,
    projectId: activeTestCase.projectId,
    workspaceId: activeTestCase.workspaceId,
  } as TestCase;

  yield put(cloneTestCaseOptimistic(newTestCase));
  yield put(setTestCaseSetting(newTestCase.id, { ...activeTestCaseSetting }));
  yield put(activateTestCase(newTestCase.id));
  const data = yield call(service.createTestCase, newTestCase);
  yield put(cloneTestCaseSuccess(data));
}

function* deleteTestCaseSaga({ payload: { testCaseId } }: any): any {
  yield put(deleteTestCaseOptimistic(testCaseId));
  yield put(deleteTestCaseSettings(testCaseId));
  const data = yield call(service.deleteTestCase, testCaseId);
  yield put(deleteTestCaseSuccess(data));
}

function* ProcessTestCaseUpdateSaga({ payload }: any): any {
  const { testCase } = payload;
  const existingTestCase = yield select(testCaseSelectors.testCaseById(testCase.id));
  if (!existingTestCase || existingTestCase.version < testCase.version) {
    yield put(syncTestCaseUpdate(testCase));
  }
}

function* ProcessTestCaseDeleteSaga({ payload }: any): any {
  const testCase = yield select(testCaseSelectors.testCaseById(payload.testCase.id));
  if (testCase) {
    yield put(syncTestCaseDelete(testCase.id));
    yield put(deleteTestCaseSettings(testCase.id));
  }
}

function* testCasePanelLoaded(): any {
  const requestChan = yield actionChannel(TEST_CASE_PANEL_LOADED);
  yield take(requestChan);

  yield spawn(watchRunSingleTestCase);
  yield spawn(watchRunAllMode);

  const watchModeOn = yield select(settingSelectors.watchModeOn);
  if (watchModeOn) {
    yield put(startWatchMode());
  }

  const lastAccessedTestCaseId = yield select(settingSelectors.lastAccessedTestCaseId);
  if (lastAccessedTestCaseId) {
    const testCase = yield select(testCaseSelectors.testCaseById(lastAccessedTestCaseId));
    if (testCase) {
      yield put(activateTestCase(testCase.id));
    }
  }
  const sortByType = yield select(settingSelectors.sortByType);
  if (sortByType) {
    yield put(sortTestCases(sortByType));
  }
}

function* loadTestCasesSaga(): any {
  try {
    const rootProjectId = yield select(projectSelectors.projectTreeRootId);

    const allTestcases: any = [];
    const testCases: TestCase[] = yield call(service.getAllTestcases, rootProjectId);
    allTestcases.push(Object.values(testCases));
    yield put(loadAllTestcasesOptimistic(allTestcases));
  } catch (e) {
    console.error(e);
    yield put(apiError(e));
  }
}

function* loadTestCasesOptimisticSaga(): any {
  const isWatchModeOn = yield select(settingSelectors.isWatchModeOn);
  if (isWatchModeOn) {
    yield put(stopWatchMode());
    yield put(startWatchMode());
  }
}

export const testCaseSaga = [
  throttle(500, SAVE_FACTS, saveInputSaga),
  throttle(500, SAVE_UNWANTED_RESULTS, saveInputSaga),
  throttle(500, SAVE_REQUIRED_RESULTS, saveInputSaga),
  throttle(500, SAVE_DESCRIPTION, saveInputSaga),
  throttle(500, SAVE_TITLE, saveInputSaga),
  takeEvery(COPY_TEST_CASE, copyTestCaseSaga),
  takeEvery(CREATE_NEW_TEST_CASE, createTestCaseSaga),
  takeEvery(DELETE_TEST_CASE, deleteTestCaseSaga),
  takeEvery(CLONE_TEST_CASE, cloneTestCaseSaga),
  takeEvery(PROCESS_TEST_CASE_UPDATE_SUBSCRIPTION, ProcessTestCaseUpdateSaga),
  takeEvery(PROCESS_TEST_CASE_DELETE_SUBSCRIPTION, ProcessTestCaseDeleteSaga),
  takeEvery(LOAD_ALL_TESTCASES, loadTestCasesSaga),
  takeEvery(LOAD_ALL_TESTCASES_OPTIMISTIC, loadTestCasesOptimisticSaga),
  fork(testCasePanelLoaded),
];
