import { ENABLE_AUTH, HIDE_LOGIN, LOGIN_SUCCESS, LOGOUT_SUCCESS } from './actions';

export interface AuthState {
  enableAuth: boolean;
  authStateFinalized: boolean;
  userId: string | null;
  isLoggedIn: boolean;
}

const initialState: AuthState = {
  enableAuth: true,
  authStateFinalized: false,
  userId: null,
  isLoggedIn: false,
};

export interface Action {
  type: string;
  payload?: any;
}

export const authReducer = (state: AuthState = initialState, action: Action): AuthState => {
  const { type, payload } = action;

  switch (type) {
    case ENABLE_AUTH: {
      return {
        ...state,
        enableAuth: true,
      };
    }

    case LOGIN_SUCCESS: {
      const { userId } = payload;
      return {
        ...state,
        userId,
        authStateFinalized: true,
        isLoggedIn: true,
      };
    }

    case LOGOUT_SUCCESS:
    case HIDE_LOGIN: {
      return {
        ...state,
        userId: null,
        authStateFinalized: true,
        isLoggedIn: false,
      };
    }

    default:
      return state;
  }
};
