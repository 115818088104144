import { createSelector } from 'reselect';
import * as _ from 'lodash';
import { DashboardState } from './reducer';

const dashboardState = (state: any) => {
  return state.dashboard as DashboardState;
};

const activeDashboardId = createSelector(dashboardState, ({ activeDashboardId }) => activeDashboardId);

const userDashboardId = createSelector(dashboardState, ({ activeUserDashboardId }) => activeUserDashboardId);

const activeUserEmailId = createSelector(dashboardState, ({ activeUserEmailId }) => activeUserEmailId);

const activeDashboard = createSelector(
  dashboardState,
  activeDashboardId,
  (state: DashboardState, activeDashboardId: string) => {
    return state.dashboardMap[activeDashboardId] || {};
  }
);

const activeDashboardName = createSelector(activeDashboard, ({ name }) => name);

const isLoading = createSelector(dashboardState, ({ loading }) => loading);

const error = createSelector(dashboardState, ({ error }) => error);

const projects: any = (currentId: string) => {
  return createSelector(dashboardState, (state: DashboardState) => {
    return (state.dashboardMap[currentId] && state.dashboardMap[currentId].projects) || [];
  });
};

const activeDashboardProjects = createSelector(
  dashboardState,
  ({ activeDashboardId, dashboardMap }) =>
    (dashboardMap[activeDashboardId] && dashboardMap[activeDashboardId].projects) || []
);

const dashboardById = (dashboardId: string) => {
  return createSelector(dashboardState, ({ dashboardMap }: DashboardState) => {
    return dashboardMap[dashboardId] || {};
  });
};

const loading = createSelector(dashboardState, ({ loading }) => loading);

export const dashboardSelector = {
  isLoading,
  activeDashboardId,
  userDashboardId,
  activeUserEmailId,
  activeDashboard,
  activeDashboardName,
  dashboardById,
  activeDashboardProjects,
  projects,
  loading,
  error,
};
