import { Project } from './reducer';

export const CREATE_PROJECT_REQUEST = 'project/CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_OPTIMISTIC = 'project/CREATE_PROJECT_OPTIMISTIC';
export const CREATE_PROJECT_SUCCESS = 'project/CREATE_PROJECT_SUCCESS';
export const REFRESH_PROJECT = 'project/REFRESH_PROJECT';
export const REFRESH_PROJECT_SUCCESS = 'project/REFRESH_PROJECT_SUCCESS';
export const LOAD_PROJECT_REQUEST = 'project/LOAD_PROJECT_REQUEST';
export const LOAD_PROJECT_SUCCESS = 'project/LOAD_PROJECT_SUCCESS';
export const LOAD_ALL_PROJECTS = 'project/LOAD_ALL_PROJECTS';
export const LOAD_ALL_PROJECTS_SUCCESS = 'project/LOAD_ALL_PROJECTS_SUCCESS';
export const LOAD_ALL_SUB_PROJECTS = 'project/LOAD_ALL_SUB_PROJECTS';
export const LOAD_ALL_SUB_PROJECTS_SUCCESS = 'project/LOAD_ALL_SUB_PROJECTS_SUCCESS';
export const UPDATE_PROJECT_NAME = 'project/UPDATE_PROJECT_NAME';
export const UPDATE_PROJECT_SUCCESS = 'project/UPDATE_PROJECT_SUCCESS';
export const ADD_PROJECT_REFERENCE = 'project/ADD_PROJECT_REFERENCE';
export const ADD_PROJECT_REFERENCE_OPTIMISTIC = 'project/ADD_PROJECT_REFERENCE_OPTIMISTIC';
export const ADD_PROJECT_REFERENCE_SUCCESS = 'project/ADD_PROJECT_REFERENCE_SUCCESS';
export const REMOVE_PROJECT_REFERENCE = 'project/REMOVE_PROJECT_REFERENCE';
export const REMOVE_PROJECT_REFERENCE_OPTIMISTIC = 'project/REMOVE_PROJECT_REFERENCE_OPTIMISTIC';
export const REMOVE_PROJECT_REFERENCE_SUCCESS = 'project/REMOVE_PROJECT_REFERENCE_SUCCESS';
export const CREATE_DOCUMENT = 'project/CREATE_DOCUMENT';
export const DELETE_PROJECT = 'project/DELETE_PROJECT';
export const DELETE_PROJECT_OPTIMISTIC = 'project/DELETE_PROJECT_OPTIMISTIC ';
export const DELETE_PROJECT_SUCCESS = 'project/DELETE_PROJECT_SUCCESS';
export const REMOVE_PROJECT = 'project/REMOVE_PROJECT';
export const REMOVE_PROJECT_OPTIMISTIC = 'project/REMOVE_PROJECT_OPTIMISTIC ';
export const REMOVE_PROJECT_SUCCESS = 'project/REMOVE_PROJECT_SUCCESS';

export const RESTORE_PROJECT = 'project/RESTORE_PROJECT';
export const RESTORE_PROJECT_OPTIMISTIC = 'project/RESTORE_PROJECTOPTIMISTIC';
export const RESTORE_PROJECT_SUCCESS = 'project/RESTORE_PROJECT_SUCCESS';

export const COPY_PROJECT = 'project/COPY_PROJECT';
export const COPY_PROJECT_OPTIMISTIC = 'project/COPY_PROJECT_OPTIMISTIC ';
export const COPY_PROJECT_SUCCESS = 'project/COPY_PROJECT_SUCCESS';
export const EXPORT_PROJECT = 'project/EXPORT_PROJECT';
export const EXPORT_PROJECT_OPTIMISTIC = 'project/EXPORT_PROJECT_OPTIMISTIC ';
export const EXPORT_PROJECT_SUCCESS = 'project/EXPORT_PROJECT_SUCCESS';
export const UPDATE_DOCUMENT_DESC = 'project/UPDATE_DOCUMENT_DESC';
export const UPDATE_DOCUMENT_DESC_OPTIMISTIC = 'project/UPDATE_DOCUMENT_DESC_OPTIMISTIC';
export const UPDATE_DOCUMENT_DESC_SUCCESS = 'project/UPDATE_DOCUMENT_DESC_SUCCESS';
export const UPDATE_DOCUMENT_NAME = 'project/UPDATE_DOCUMENT_NAME';
export const UPDATE_DOCUMENT_NAME_OPTIMISTIC = 'project/UPDATE_DOCUMENT_NAME_OPTIMISTIC';
export const UPDATE_DOCUMENT_NAME_SUCCESS = 'project/UPDATE_DOCUMENT_NAME_SUCCESS';
export const REMOVE_DOCUMENT = 'project/REMOVE_DOCUMENT';
export const REMOVE_DOCUMENT_OPTIMISTIC = 'project/REMOVE_DOCUMENT_OPTIMISTIC ';
export const REMOVE_DOCUMENT_SUCCESS = 'project/REMOVE_DOCUMENT_SUCCESS';
export const RESTORE_DOCUMENT = 'project/RESTORE_DOCUMENT';
export const RESTORE_DOCUMENT_OPTIMISTIC = 'project/RESTORE_DOCUMENT_OPTIMISTIC ';
export const RESTORE_DOCUMENT_SUCCESS = 'project/RESTORE_DOCUMENT_SUCCESS';
export const LINK_PROJECT = 'project/LINK_PROJECT';
export const LINK_PROJECT_OPTIMISTIC = 'project/LINK_PROJECT_OPTIMISTIC';
export const LINK_PROJECT_SUCCESS = 'project/LINK_PROJECT_SUCCESS';
export const UPDATE_PROJECT_TREE_ROOT_ID = 'project/ UPDATE_PROJECT_TREE_ROOT_ID';
export const UPDATE_ROOT_PROJECT_ID = 'project/UPDATE_ROOT_PROJECT_ID';
export const UPDATE_ROOT_PROJECT_ID_OPTIMISTIC = 'project/UPDATE_ROOT_PROJECT_ID_OPTIMISTIC';
export const UPDATE_ROOT_PROJECT_ID_SUCCESS = 'project/UPDATE_ROOT_PROJECT_ID_SUCCESS';
export const UPDATE_ACTIVE_PROJECT_ID = 'project/UPDATE_ACTIVE_PROJECT_ID';
export const UPDATE_PARENT_PROJECT_ID = 'project/UPDATE_PARENT_PROJECT_ID';
export const UPDATE_ACTIVE_WORKSPACE_ID = 'project/UPDATE_ACTIVE_WORKSPACE_ID';
export const UPDATE_NESTED_PARENTS = 'project/UPDATE_NESTED_PARENTS';
export const DOCUMENT_DRAG_DROP = 'project/DOCUMENT_DRAG_DROP';
export const SUBSCRIBE_PROJECT_UPDATES = 'project/SUBSCRIBE_PROJECT_UPDATES';
export const UNSUBSCRIBE_PROJECT_UPDATES = 'project/UNSUBSCRIBE_PROJECT_UPDATES';
export const SYNC_PROJECT_UPDATE = 'project/SYNC_PROJECT_UPDATE';
export const SYNC_PROJECT_REMOVE = 'project/SYNC_PROJECT_REMOVE';
export const PROCESS_UPDATE_NESTED_PARENT = 'project/PROCESS_UPDATE_NESTED_PARENT';
export const PROJECT_FLOW = 'project/PROJECT_FLOW';
export const PROJECT_HIERARCHY_ROOT_PROJECT_ID = 'project/PROJECT_HIERARCHY_ROOT_PROJECT_ID';
export const EXPORT_PROJECTS_RULES = 'project/EXPORT_PROJECTS_RULES';
export const HIDE_PARENT_LINK = 'project/HIDE_PARENT_LINK';
export const API_ERROR = 'project/API_ERROR';
export const createProject = (dashboardId?: string, email?: string) => ({
  type: CREATE_PROJECT_REQUEST,
  payload: {
    dashboardId,
    email,
  },
});

export const createProjectOptimistic = (project: Project) => ({
  type: CREATE_PROJECT_OPTIMISTIC,
  payload: {
    project,
  },
});

export const projectCreated = (project: Project, actor: string) => ({
  type: CREATE_PROJECT_SUCCESS,
  payload: {
    project,
    actor,
  },
});

export const loadProject = (projectId: string) => ({
  type: LOAD_PROJECT_REQUEST,
  payload: {
    projectId,
  },
});

export const projectLoaded = (project: Project) => ({
  type: LOAD_PROJECT_SUCCESS,
  payload: {
    project,
  },
});

export const refreshProject = (projectId: string) => ({
  type: REFRESH_PROJECT,
  payload: projectId,
});

export const projectRefreshed = (project: Project, workspaceId: string) => ({
  type: REFRESH_PROJECT_SUCCESS,
  payload: {
    project,
    workspaceId,
  },
});

export const updateProjectName = (projectId: string, name: string) => ({
  type: UPDATE_PROJECT_NAME,
  payload: {
    projectId,
    name,
  },
});

export const projectNameUpdated = (project: Project) => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: project,
});

export const addProjectRef = (dashboardId: string, refProjectId: string, currentProjectId: string) => ({
  type: ADD_PROJECT_REFERENCE,
  payload: {
    dashboardId,
    refProjectId,
    currentProjectId,
  },
});

export const addProjectRefOptimistic = (refProjectId: string, currentProjectId: string) => ({
  type: ADD_PROJECT_REFERENCE_OPTIMISTIC,
  payload: {
    refProjectId,
    currentProjectId,
  },
});

export const projectRefAdded = (project: Project) => ({
  type: ADD_PROJECT_REFERENCE_SUCCESS,
  payload: project,
});

export const removeProjectRef = (refProjectId: string, currentProjectId: string) => ({
  type: REMOVE_PROJECT_REFERENCE,
  payload: {
    refProjectId,
    currentProjectId,
  },
});

export const removeProjectRefOptimistic = (refProjectId: string, currentProjectId: string) => ({
  type: REMOVE_PROJECT_REFERENCE_OPTIMISTIC,
  payload: {
    refProjectId,
    currentProjectId,
  },
});

export const projectRefRemoved = (project: Project) => ({
  type: REMOVE_PROJECT_REFERENCE_SUCCESS,
  payload: project,
});

export const deleteProject = (projectId: string) => ({
  type: DELETE_PROJECT,
  payload: {
    projectId,
  },
});

export const deleteProjectOptimistic = (projectId: string) => ({
  type: DELETE_PROJECT_OPTIMISTIC,
  payload: {
    projectId,
  },
});

export const deleteProjectSuccess = (project: Project) => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: {
    project,
  },
});

export const removeProject = (containerId: string, projectId: string) => ({
  type: REMOVE_PROJECT,
  payload: {
    containerId,
    projectId,
  },
});

export const removeProjectOptimistic = (containerId: string, projectId: string) => ({
  type: REMOVE_PROJECT_OPTIMISTIC,
  payload: {
    containerId,
    projectId,
  },
});

export const removeProjectSuccess = (project: Project) => ({
  type: REMOVE_PROJECT_SUCCESS,
  payload: {
    project,
  },
});

export const restoreProject = (projectId: string, containerId: string | null) => ({
  type: RESTORE_PROJECT,
  payload: {
    projectId,
    containerId,
  },
});

export const restoreProjectOptimistic = (projectId: string, containerId: string) => ({
  type: RESTORE_PROJECT_OPTIMISTIC,
  payload: {
    projectId,
    containerId,
  },
});

export const restoreProjectSuccess = (project: Project) => ({
  type: RESTORE_PROJECT_SUCCESS,
  payload: {
    project,
  },
});

export const copyProject = (projectId: string) => ({
  type: COPY_PROJECT,
  payload: {
    projectId,
  },
});

export const copyProjectOptimistic = (project: Project) => ({
  type: COPY_PROJECT_OPTIMISTIC,
  payload: {
    project,
  },
});

export const copyProjectSuccess = (project: Project) => ({
  type: COPY_PROJECT_SUCCESS,
  payload: {
    project,
  },
});

export const exportProject = (projectId: string) => ({
  type: EXPORT_PROJECT,
  payload: {
    projectId,
  },
});

export const exportProjectOptimistic = (project: Project) => ({
  type: EXPORT_PROJECT_OPTIMISTIC,
  payload: {
    project,
  },
});

export const exportProjectSuccess = (project: Project) => ({
  type: EXPORT_PROJECT_SUCCESS,
  payload: {
    project,
  },
});

export const updateProjectTreeRootId = (projectId: string) => ({
  type: UPDATE_PROJECT_TREE_ROOT_ID,
  payload: {
    projectId,
  },
});

export const updateRootProjectId = (projectId: string, rootProjectId: string) => ({
  type: UPDATE_ROOT_PROJECT_ID,
  payload: {
    projectId,
    rootProjectId,
  },
});

export const updateRootProjectIdOptimistic = (projectId: string, rootProjectId: string) => ({
  type: UPDATE_ROOT_PROJECT_ID_OPTIMISTIC,
  payload: {
    projectId,
    rootProjectId,
  },
});

export const updateRootProjectIdSuccess = (project: Project) => ({
  type: UPDATE_ROOT_PROJECT_ID_SUCCESS,
  payload: {
    project,
  },
});

export const updateActiveProjectId = (projectId: string) => ({
  type: UPDATE_ACTIVE_PROJECT_ID,
  payload: {
    projectId,
  },
});

export const updateParentProjectId = (projectId: string) => ({
  type: UPDATE_PARENT_PROJECT_ID,
  payload: {
    projectId,
  },
});

export const updateActiveWorkspaceId = (workspaceId: string) => ({
  type: UPDATE_ACTIVE_WORKSPACE_ID,
  payload: {
    workspaceId,
  },
});

export const loadAllProjects = (dashboardId?: string) => ({
  type: LOAD_ALL_PROJECTS,
  payload: {
    dashboardId,
  },
});

export const allProjectsLoaded = (projects: Project[]) => ({
  type: LOAD_ALL_PROJECTS_SUCCESS,
  payload: projects,
});

export const linkProject = (projectId: string) => ({
  type: LINK_PROJECT,
  payload: {
    projectId,
  },
});
export const linkProjectOptimistic = (projectId: string) => ({
  type: LINK_PROJECT_OPTIMISTIC,
  payload: {
    projectId,
  },
});

export const linkProjectSuccess = (project: Project) => ({
  type: LINK_PROJECT_SUCCESS,
  payload: {
    project,
  },
});

export const hideParentLink = (projectIds: string[]) => ({
  type: HIDE_PARENT_LINK,
  payload: {
    projectIds,
  },
});

export const updateDocumentDesc = (projectId: string, documentId: string, documentDesc: string) => ({
  type: UPDATE_DOCUMENT_DESC,
  payload: {
    projectId,
    documentId,
    documentDesc,
  },
});

export const updateDocumentDescOptimistic = (projectId: string, documentId: string, documentDesc: string) => ({
  type: UPDATE_DOCUMENT_DESC_OPTIMISTIC,
  payload: {
    projectId,
    documentId,
    documentDesc,
  },
});

export const documentDescUpdated = (project: Project) => ({
  type: UPDATE_DOCUMENT_DESC_SUCCESS,
  payload: {
    project,
  },
});

export const updateDocumentName = (projectId: string, documentId: string, documentName: string) => ({
  type: UPDATE_DOCUMENT_NAME,
  payload: {
    projectId,
    documentId,
    documentName,
  },
});

export const updateDocumentNameOptimistic = (projectId: string, documentId: string, documentName: string) => ({
  type: UPDATE_DOCUMENT_NAME_OPTIMISTIC,
  payload: {
    projectId,
    documentId,
    documentName,
  },
});

export const documentNameUpdated = (project: Project) => ({
  type: UPDATE_DOCUMENT_NAME_SUCCESS,
  payload: {
    project,
  },
});

export const removeDocument = (workspaceId: string, projectId: string) => ({
  type: REMOVE_DOCUMENT,
  payload: {
    workspaceId,
    projectId,
  },
});

export const removeDocumentOptimistic = (workspaceId: string, projectId: string) => ({
  type: REMOVE_DOCUMENT_OPTIMISTIC,
  payload: {
    workspaceId,
    projectId,
  },
});

export const removeDocumentSuccess = (project: Project) => ({
  type: REMOVE_DOCUMENT_SUCCESS,
  payload: project,
});

export const restoreDocument = (workspaceId: string, projectId: string) => ({
  type: RESTORE_DOCUMENT,
  payload: {
    workspaceId,
    projectId,
  },
});

export const restoreDocumentOptimistic = (workspaceId: string, projectId: string) => ({
  type: RESTORE_DOCUMENT_OPTIMISTIC,
  payload: {
    workspaceId,
    projectId,
  },
});

export const restoreDocumentSuccess = (project: Project) => ({
  type: RESTORE_DOCUMENT_SUCCESS,
  payload: project,
});

export const createDocument = (activeDashboardId: string, projectId: string) => ({
  type: CREATE_DOCUMENT,
  payload: {
    activeDashboardId,
    projectId,
  },
});

export const reOrderDocuments = (projectId: string, startIndex: number, destinationIndex: number) => ({
  type: DOCUMENT_DRAG_DROP,
  payload: {
    projectId,
    startIndex,
    destinationIndex,
  },
});

export const updateNestedParents = (projects: Project[]) => ({
  type: UPDATE_NESTED_PARENTS,
  payload: {
    projects,
  },
});

export const loadAllSubProjects = (rootProjectId?: string) => ({
  type: LOAD_ALL_SUB_PROJECTS,
  payload: {
    rootProjectId,
  },
});

export const allSubProjectsLoaded = (projects: Project[]) => ({
  type: LOAD_ALL_SUB_PROJECTS_SUCCESS,
  payload: projects,
});

export const processAndUpdateNestedParents = (projectIds: string[]) => ({
  type: PROCESS_UPDATE_NESTED_PARENT,
  payload: { projectIds },
});

//
export const subscribeToProjectUpdates = (workspaceId: string, projectId: string, projectTreeRootId: string) => ({
  type: SUBSCRIBE_PROJECT_UPDATES,
  payload: {
    workspaceId,
    projectId,
    projectTreeRootId,
  },
});

export const unsubscribeFromProjectUpdates = () => ({
  type: UNSUBSCRIBE_PROJECT_UPDATES,
});

export const syncProjectUpdate = (project: Project) => ({
  type: SYNC_PROJECT_UPDATE,
  payload: {
    project,
  },
});

export const syncProjectRemove = (projectId: string) => ({
  type: SYNC_PROJECT_REMOVE,
  payload: {
    projectId,
  },
});

export const projectFlow = (flow: string) => ({
  type: PROJECT_FLOW,
  payload: {
    flow,
  },
});
export const projectHierRootProjectId = (projectId: string) => ({
  type: PROJECT_HIERARCHY_ROOT_PROJECT_ID,
  payload: {
    projectId,
  },
});

export const exportProjectsRules = (projectId: string) => ({
  type: EXPORT_PROJECTS_RULES,
  payload: {
    projectId,
  },
});

export const apiError = (error: any) => ({
  type: API_ERROR,
  payload: {
    error,
  },
});
