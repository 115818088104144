import { SettingState, TestCaseSetting } from './reducer';
import { Segment } from '../segment';

const ObjectID = require('bson-objectid');

export const WORKSPACE_SETTING_LOADED = 'setting/WORKSPACE_SETTING_LOADED';
export const CLOSE_SEGMENT_PANEL = 'setting/CLOSE_SEGMENT_PANEL';
export const OPEN_SEGMENT_PANEL = 'setting/OPEN_SEGMENT_PANEL';
export const CLOSE_TEST_CASE_PANEL = 'setting/CLOSE_TEST_CASE_PANEL';
export const OPEN_TEST_CASE_PANEL = 'setting/OPEN_TEST_CASE_PANEL';
export const SYNTAX_HIGHLIGHT_TOGGLE = 'setting/SYNTAX_HIGHLIGHT_TOGGLE';
export const WORD_WRAP_TOGGLE = 'setting/WORD_WRAP_TOGGLE';
export const TEST_CASE_WORD_WRAP_TOGGLE = 'setting/TEST_CASE_WORD_WRAP_TOGGLE';
export const AUTO_SUGGEST_TOGGLE = 'setting/AUTO_SUGGEST_TOGGLE';
export const TEST_CASE_AUTO_SUGGEST_TOGGLE = 'setting/TEST_CASE_AUTO_SUGGEST_TOGGLE';
export const CLOSE_RULES_PANEL = 'setting/CLOSE_RULES_PANEL';
export const OPEN_RULES_PANEL = 'setting/OPEN_RULES_PANEL';
export const UPDATE_SPLIT1_SIZE = 'setting/UPDATE_SPLIT1_SIZE';
export const UPDATE_SPLIT2_SIZE = 'setting/UPDATE_SPLIT2_SIZE';
export const UPDATE_SPLIT3_SIZE = 'setting/UPDATE_SPLIT3_SIZE';
export const CLOSE_RESULTS_PANEL = 'setting/CLOSE_RESULTS_PANEL';
export const OPEN_RESULTS_PANEL = 'setting/OPEN_RESULTS_PANEL';
export const CLOSE_TEST_CASE_DRAWER = 'setting/CLOSE_TEST_CASE_DRAWER';
export const OPEN_TEST_CASE_DRAWER = 'setting/OPEN_TEST_CASE_DRAWER';
export const TOGGLE_DESCRIPTION = 'setting/TOGGLE_DESCRIPTION';
export const TOGGLE_FACTS = 'setting/TOGGLE_FACTS';
export const TOGGLE_REQUIRED_RESULTS = 'setting/TOGGLE_REQUIRED_RESULTS';
export const TOGGLE_UNWANTED_RESULTS = 'setting/TOGGLE_UNWANTED_RESULTS';
export const DELETE_TEST_CASE_SETTING = 'setting/DELETE_TEST_CASE_SETTING';
export const SET_TEST_CASE_SETTING = 'setting/SET_TEST_CASE_SETTING';
export const TOGGLE_AUTO_SCROLL = 'setting/TOGGLE_AUTO_SCROLL';
export const CLOSE_PROJECT_DRAWER = 'setting/CLOSE_PROJECT_DRAWER';
export const OPEN_PROJECT_DRAWER = 'setting/OPEN_PROJECT_DRAWER';
export const ADD_PROJECT_INITIATOR = 'setting/ADD_PROJECT_INITIATOR';
export const IMPORT_PROJECT_TARGET = 'setting/IMPORT_PROJECT_TARGET';
export const HIGHLIGHT_PROJECT_ID = 'setting/HIGHLIGHT_PROJECT_ID';
export const MASTER_PROJECT_ID = 'setting/MASTER_PROJECT_ID';

export const DASHBOARD_URL_SAVE_MSG = 'setting/DASHBOARD_URL_SAVE_MSG';
export const DASHBOARD_SETTING_LOADED = 'setting/DASHBOARD_SETTING_LOADED';
export const COLLAPSE_SEGMENT = 'setting/COLLAPSE_SEGMENT';
export const UNCOLLAPSE_SEGMENT = 'setting/UNCOLLAPSE_SEGMENT';

export const workspaceSettingLoaded = (setting: SettingState) => ({
  type: WORKSPACE_SETTING_LOADED,
  payload: {
    setting,
  },
});

export const closeSegmentPanel = () => ({
  type: CLOSE_SEGMENT_PANEL,
});

export const openSegmentPanel = () => ({
  type: OPEN_SEGMENT_PANEL,
});

export const closeTestCasePanel = () => ({
  type: CLOSE_TEST_CASE_PANEL,
});

export const openTestCasePanel = () => ({
  type: OPEN_TEST_CASE_PANEL,
});

export const closeRulesPanel = () => ({
  type: CLOSE_RULES_PANEL,
});

export const openRulesPanel = () => ({
  type: OPEN_RULES_PANEL,
});

export const toggleSyntaxHighight = () => ({
  type: SYNTAX_HIGHLIGHT_TOGGLE,
});

export const toggleWordWrap = () => ({
  type: WORD_WRAP_TOGGLE,
});

export const toggleTestCaseWordWrap = () => ({
  type: TEST_CASE_WORD_WRAP_TOGGLE,
});

export const toggleAutoSuggest = () => ({
  type: AUTO_SUGGEST_TOGGLE,
});

export const toggleTestCaseAutoSuggest = () => ({
  type: TEST_CASE_AUTO_SUGGEST_TOGGLE,
});

export const updateSplit1Size = (size: number | string) => ({
  type: UPDATE_SPLIT1_SIZE,
  payload: {
    size,
  },
});

export const updateSplit2Size = (size: number | string) => ({
  type: UPDATE_SPLIT2_SIZE,
  payload: {
    size,
  },
});

export const updateSplit3Size = (size: number | string) => ({
  type: UPDATE_SPLIT3_SIZE,
  payload: {
    size,
  },
});

export const closeResultsPanel = () => ({
  type: CLOSE_RESULTS_PANEL,
});

export const openResultsPanel = () => ({
  type: OPEN_RESULTS_PANEL,
});

export const openTestCaseDrawer = () => ({
  type: OPEN_TEST_CASE_DRAWER,
});

export const closeTestCaseDrawer = () => ({
  type: CLOSE_TEST_CASE_DRAWER,
});

export const openProjectDrawer = () => ({
  type: OPEN_PROJECT_DRAWER,
});

export const closeProjectDrawer = () => ({
  type: CLOSE_PROJECT_DRAWER,
});
export const addProjectInitiator = (projectId: string) => ({
  type: ADD_PROJECT_INITIATOR,
  payload: {
    projectId,
  },
});
export const importProjectTarget = (projectIdToImport: string) => ({
  type: IMPORT_PROJECT_TARGET,
  payload: {
    projectIdToImport,
  },
});

export const highlightProjectId = (projectId: string) => ({
  type: HIGHLIGHT_PROJECT_ID,
  payload: {
    projectId,
  },
});

export const masterProjectId = (projectId: string) => ({
  type: MASTER_PROJECT_ID,
  payload: {
    projectId,
  },
});

export const toggleDescription = (testCaseId: string, descriptionCollapsed: boolean) => ({
  type: TOGGLE_DESCRIPTION,
  payload: {
    testCaseId,
    descriptionCollapsed,
  },
});

export const toggleFacts = (testCaseId: string, factsCollapsed: boolean) => ({
  type: TOGGLE_FACTS,
  payload: {
    testCaseId,
    factsCollapsed,
  },
});

export const toggleRequiredResults = (testCaseId: string, requiredResultsCollapsed: boolean) => ({
  type: TOGGLE_REQUIRED_RESULTS,
  payload: {
    testCaseId,
    requiredResultsCollapsed,
  },
});

export const toggleUnwantedResults = (testCaseId: string, unwantedResultsCollapsed: boolean) => ({
  type: TOGGLE_UNWANTED_RESULTS,
  payload: {
    testCaseId,
    unwantedResultsCollapsed,
  },
});

export const deleteTestCaseSettings = (id: string) => ({
  type: DELETE_TEST_CASE_SETTING,
  payload: {
    id,
  },
});

export const setTestCaseSetting = (id: string, setting: TestCaseSetting) => ({
  type: SET_TEST_CASE_SETTING,
  payload: {
    id,
    setting,
  },
});

export const toggleAutoScroll = () => ({
  type: TOGGLE_AUTO_SCROLL,
});

export const dashboardUrlSaveMessageDisplayed = (id: string) => ({
  type: DASHBOARD_URL_SAVE_MSG,
  payload: {
    id,
  },
});

export const dashboardSettingLoaded = (dashboardSetting: SettingState['dashboardSetting']) => ({
  type: DASHBOARD_SETTING_LOADED,
  payload: {
    dashboardSetting,
  },
});

export const collapseSegment = (startLegalTextId: string, endLegalTextId: string) => ({
  type: COLLAPSE_SEGMENT,
  payload: {
    id: ObjectID().toString(),
    startLegalTextId,
    endLegalTextId,
    type: 'collapsed',
  } as Segment,
});

export const uncollapseSegment = (id: string) => ({
  type: UNCOLLAPSE_SEGMENT,
  payload: {
    id,
  },
});
