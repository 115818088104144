import { Segment } from '../segment';
import { TestCase } from '../testCase/reducer';
import { WorkspaceState } from './reducer';

export const CREATE_WORKSPACE_REQUEST = 'workspace/CREATE_WORKSPACE_REQUEST';
export const CREATE_WORKSPACE_SUCCESS = 'workspace/CREATE_WORKSPACE_SUCCESS';
export const API_ERROR = 'workspace/API_ERROR';
export const LOAD_WORKSPACE_REQUEST = 'workspace/LOAD_WORKSPACE_REQUEST';
export const LOAD_WORKSPACE_SUCCESS = 'workspace/LOAD_WORKSPACE_SUCCESS';
export const DELETE_WORKSPACE = 'workspace/DELETE_WORKSPACE';
export const DELETE_WORKSPACE_OPTIMISTIC = 'workspace/DELETE_WORKSPACE_OPTIMISTIC';
export const DELETE_WORKSPACE_SUCCESS = 'workspace/DELETE_WORKSPACE_SUCCESS';
export const UPDATE_WORKSPACE_DESC = 'workspace/UPDATE_WORKSPACE_DESC';
export const UPDATE_WORKSPACE_DESC_OPTIMISTIC = 'workspace/UPDATE_WORKSPACE_DESC_OPTIMISTIC';
export const UPDATE_WORKSPACE_DESC_SUCCESS = 'workspace/UPDATE_WORKSPACE_DESC_SUCCESS';
export const UPDATE_WORKSPACE_NAME = 'workspace/UPDATE_WORKSPACE_NAME';
export const UPDATE_WORKSPACE_NAME_OPTIMISTIC = 'workspace/UPDATE_WORKSPACE_NAME_OPTIMISTIC';
export const UPDATE_WORKSPACE_NAME_SUCCESS = 'workspace/UPDATE_WORKSPACE_NAME_SUCCESS';
export const ADD_WORKSPACE_SUCCESS = 'workspace/ADD_WORKSPACE_SUCCESS';
export const ADD_WORKSPACE = 'workspace/ADD_WORKSPACE';
export const WORKSPACE_READ_MODE = 'workspace/WORKSPACE_READ_MODE';

export const createWorkspace = (projectId: string, file: File, activeDashboardId: string) => ({
  type: CREATE_WORKSPACE_REQUEST,
  payload: {
    projectId,
    file,
    activeDashboardId,
  },
});

export const apiError = (error: any) => ({
  type: API_ERROR,
  payload: {
    error,
  },
});

export const workspaceCreated = (
  id: string,
  convertedHTML: string,
  legalTextCount: number,
  name: string | null,
  desc: string | null,
  urlPath: string,
  migrated: boolean,
  projectId: string,
  activeDashboardId: string
) => ({
  type: CREATE_WORKSPACE_SUCCESS,
  payload: {
    id,
    convertedHTML,
    legalTextCount,
    name,
    desc,
    urlPath,
    migrated,
    projectId,
    activeDashboardId,
  },
});

export const loadWorkspace = (id: string, isAuthenticated = false, userId?: string | undefined) => ({
  type: LOAD_WORKSPACE_REQUEST,
  payload: {
    id,
    isAuthenticated,
    userId,
  },
});

export const workspaceLoaded = (
  id: string,
  convertedHTML: string,
  legalTextCount: number,
  name: string,
  desc: string,
  urlPath: string,
  migrated: boolean,
  segments: Segment[],
  testCases: TestCase[]
) => ({
  type: LOAD_WORKSPACE_SUCCESS,
  payload: {
    id,
    convertedHTML,
    legalTextCount,
    name,
    desc,
    urlPath,
    migrated,
    segments,
    testCases,
  },
});

export const workspaceAdded = (
  id: string,
  convertedHTML: string,
  legalTextCount: number,
  name: string | null,
  desc: string | null,
  urlPath: string | null,
  migrated: boolean,
  actor: string | null
) => ({
  type: ADD_WORKSPACE_SUCCESS,
  payload: {
    id,
    convertedHTML,
    legalTextCount,
    name,
    desc,
    urlPath,
    migrated,
    actor,
  },
});

export const deleteWorkspace = (id: string) => ({
  type: DELETE_WORKSPACE,
  payload: {
    id,
  },
});

export const deleteWorkspaceOptimistic = (id: string) => ({
  type: DELETE_WORKSPACE_OPTIMISTIC,
  payload: {
    id,
  },
});

export const deleteWorkspaceSuccess = (id: string) => ({
  type: DELETE_WORKSPACE_SUCCESS,
  payload: {
    id,
  },
});

export const updateWorkspaceDesc = (id: string, desc: string) => ({
  type: UPDATE_WORKSPACE_DESC,
  payload: {
    id,
    desc,
  },
});

export const updateWorkspaceDescOptimistic = (workspace: WorkspaceState, desc: string) => ({
  type: UPDATE_WORKSPACE_DESC_OPTIMISTIC,
  payload: {
    workspace,
    desc,
  },
});

export const updateWorkspaceDescSuccess = (workspace: WorkspaceState) => ({
  type: UPDATE_WORKSPACE_DESC_SUCCESS,
  payload: {
    workspace,
  },
});

export const updateWorkspaceName = (id: string, name: string) => ({
  type: UPDATE_WORKSPACE_NAME,
  payload: {
    id,
    name,
  },
});

export const updateWorkspaceNameOptimistic = (workspace: WorkspaceState, name: string) => ({
  type: UPDATE_WORKSPACE_NAME_OPTIMISTIC,
  payload: {
    workspace,
    name,
  },
});

export const updateWorkspaceNameSuccess = (workspace: WorkspaceState) => ({
  type: UPDATE_WORKSPACE_NAME_SUCCESS,
  payload: {
    workspace,
  },
});

export const setWorkspaceReadMode = (flag: boolean) => ({
  type: WORKSPACE_READ_MODE,
  payload: {
    flag,
  },
});
