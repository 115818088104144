export const ENABLE_AUTH = 'auth/ENABLE_AUTH';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const INIT_AUTH_STATE = 'auth/INIT_AUTH_STATE';
export const HIDE_LOGIN = 'auth/HIDE_LOGIN';

export const enableAuth = () => ({
  type: ENABLE_AUTH,
});

export const loginSuccess = (accessToken: string, userId: string) => ({
  type: LOGIN_SUCCESS,
  payload: {
    accessToken,
    userId,
  },
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const hideLogin = () => ({
  type: HIDE_LOGIN,
});

export const initAuthState = () => ({
  type: INIT_AUTH_STATE,
});
