import { ResultsLineStatus } from '../store/testCase';

export const StatusSymbol: any = {
  [ResultsLineStatus.FAIL_UNWANTED]: '▲',
  [ResultsLineStatus.FAIL_REQUIRED]: '✗',
  [ResultsLineStatus.SUCCESS_REQUIRED]: '✔',
  [ResultsLineStatus.SUCCESS_UNWANTED]: '─',
};

export const TOOLBAR_HEIGHT = 38;
