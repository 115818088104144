import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/addon/hint/show-hint.css';
import { wrapWithProvider } from './wrap-with-provider';

if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
  require('./src/codemirror/turnipMode');
  require('codemirror/lib/codemirror');
  require('codemirror/addon/display/placeholder');
  require('codemirror/addon/search/match-highlighter');
  require('codemirror/addon/selection/active-line');
  require('codemirror/addon/hint/show-hint');
}

export const wrapRootElement = wrapWithProvider;
