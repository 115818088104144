import { GridState } from './reducer';

export const UPDATE_RULE_BOX_HEIGHT = 'grid/UPDATE_RULE_BOX_HEIGHT';
export const GRID_ITEMS_UPDATED = 'grid/GRID_ITEMS_UPDATED';
export const UPDATE_SNAP_GUTTER = 'grid/UPDATE_SNAP_GUTTER';
export const START_SCROLL_OBSERVER = 'grid/START_SCROLL_OBSERVER';
export const STOP_SCROLL_OBSERVER = 'grid/STOP_SCROLL_OBSERVER';
export const RESTART_SCROLL_OBSERVER = 'grid/RESTART_SCROLL_OBSERVER';
export const SEGMENT_IN_VIEW = 'grid/SEGMENT_IN_VIEW';
export const SEGMENT_OUT_OF_VIEW = 'grid/SEGMENT_OUT_OF_VIEW';
export const SNAP_SEGMENT_TO_RULE = 'grid/SNAP_SEGMENT_TO_RULE';
export const SEGMENT_RULE_PANE_SCROLLED = 'grid/SEGMENT_RULE_PANE_SCROLLED';
export const SEGMENT_PANE_SCROLLED = 'grid/SEGMENT_PANE_SCROLLED';
export const SNAP_RULE_TO_SEGMENT = 'grid/SNAP_RULE_TO_SEGMENT';
export const STACK_RULES_IN_ORDER = 'grid/STACK_RULES_IN_ORDER';
export const SNAP_BEST_POSSIBLE_RULE = 'grid/SNAP_BEST_POSSIBLE_RULE';
export const ACTIVE_RULE_VISIBILITY = 'grid/ACTIVE_RULE_VISIBILITY';
export const RULES_PANEL_WIDTH = 'grid/RULES_PANEL_WIDTH';
export const RULES_PANEL_HEIGHT = 'grid/RULES_PANEL_HEIGHT';
export const RULES_PANEL_DEFAULT_HEIGHT = 'grid/RULES_PANEL_DEFAULT_HEIGHT';
export const SEGMENT_PANEL_WIDTH = 'grid/SEGMENT_PANEL_WIDTH';

export const gridItemsUpdated = (gridMap: GridState['gridMap']) => ({
  type: GRID_ITEMS_UPDATED,
  payload: {
    gridMap,
  },
});

export const updateRuleBoxHeight = (segmentId: string, newHeight: number) => ({
  type: UPDATE_RULE_BOX_HEIGHT,
  payload: {
    segmentId,
    newHeight,
  },
});

export const updateSnapGutterHeight = (snapGutterHeight: number) => ({
  type: UPDATE_SNAP_GUTTER,
  payload: {
    snapGutterHeight,
  },
});

export const startScrollObserver = () => ({
  type: START_SCROLL_OBSERVER,
});

export const stopScrollObserver = () => ({
  type: STOP_SCROLL_OBSERVER,
});

export const segmentInView = (segmentId: string) => ({
  type: SEGMENT_IN_VIEW,
  payload: {
    segmentId,
  },
});

export const segmentOutOfView = (segmentId: string) => ({
  type: SEGMENT_OUT_OF_VIEW,
  payload: {
    segmentId,
  },
});

export const restartScrollObserver = () => ({
  type: RESTART_SCROLL_OBSERVER,
});

export const snapSegmentToRule = () => ({
  type: SNAP_SEGMENT_TO_RULE,
});

export const segmentRulePaneScrolled = (split1ScrollPosition: number) => ({
  type: SEGMENT_RULE_PANE_SCROLLED,
  payload: {
    split1ScrollPosition,
  },
});

export const segmentPaneScrolled = (split1ScrollPosition: number) => ({
  type: SEGMENT_PANE_SCROLLED,
  payload: {
    split1ScrollPosition,
  },
});

export const snapRuleToSegment = (segmentId: string) => ({
  type: SNAP_RULE_TO_SEGMENT,
  payload: {
    segmentId,
  },
});

export const stackRulesInOrder = () => ({
  type: STACK_RULES_IN_ORDER,
});

export const snapBestPossibleRule = () => ({
  type: SNAP_BEST_POSSIBLE_RULE,
});

export const activeRuleVisibility = (isActiveRuleVisible: boolean) => ({
  type: ACTIVE_RULE_VISIBILITY,
  payload: {
    isActiveRuleVisible,
  },
});

export const rulesPanelWidth = (rulesPanelWidth: number) => ({
  type: RULES_PANEL_WIDTH,
  payload: {
    rulesPanelWidth,
  },
});

export const rulesPanelHeight = (rulesPanelHeight: number) => ({
  type: RULES_PANEL_HEIGHT,
  payload: {
    rulesPanelHeight,
  },
});

export const rulesPanelDefaultHeight = (rulesPanelDefaultHeight: number) => ({
  type: RULES_PANEL_DEFAULT_HEIGHT,
  payload: {
    rulesPanelDefaultHeight,
  },
});

export const segmentPanelWidth = (segmentPanelWidth: number) => ({
  type: SEGMENT_PANEL_WIDTH,
  payload: {
    segmentPanelWidth,
  },
});
