import { Dashboard } from './reducer';

export const CREATE_DASHBOARD_REQUEST = 'dashboard/CREATE_DASHBOARD_REQUEST';
export const CREATE_DASHBOARD_OPTIMISTIC = 'dashboard/CREATE_DASHBOARD_OPTIMISTIC';
export const CREATE_DASHBOARD_SUCCESS = 'dashboard/CREATE_DASHBOARD_SUCCESS';

export const ADD_PROJECT_LOCAL = 'dashboard/ADD_PROJECT_LOCAL';
export const ADD_PROJECT = 'dashboard/ADD_PROJECT';
export const ADD_PROJECT_OPTIMISTIC = 'dashboard/ADD_PROJECT_OPTIMISTIC';
export const ADD_PROJECT_SUCCESS = 'dashboard/ADD_PROJECT_SUCCESS';
export const COLLABORATE_PROJECT_REQUEST = 'dashboard/COLLABORATE_PROJECT_REQUEST';
export const COLLABORATE_PROJECT_SUCCESS = 'dashboard/COLLABORATE_PROJECT_SUCCESS';
export const IMPORT_PROJECT_REQUEST = 'dashboard/IMPORT_PROJECT_REQUEST';
export const IMPORT_PROJECT_SUCCESS = 'dashboard/IMPORT_PROJECT_SUCCESS';
export const ADD_PROJECTS = 'dashboard/ADD_PROJECTS';
export const ADD_PROJECTS_OPTIMISTIC = 'dashboard/ADD_PROJECTS_OPTIMISTIC';
export const ADD_PROJECTS_SUCCESS = 'dashboard/ADD_PROJECTS_SUCCESS';
export const LOAD_DASHBOARD_REQUEST = 'dashboard/LOAD_DASHBOARD_REQUEST';
export const LOAD_DASHBOARD_SUCCESS = 'dashboard/FETCH_LOAD_PROJECT';
export const LOAD_USER_DASHBOARD_SUCCESS = 'dashboard/LOAD_USER_DASHBOARD_SUCCESS';

export const FETCH_LOAD_PROJECT = 'dashboard/FETCH_LOAD_DASHBOARD';
export const FETCH_LOAD_PROJECT_SUCCESS = 'dashboard/FETCH_LOAD_PROJECT_SUCCESS';
export const SUBSCRIBE_DASHBOARD_UPDATES = 'dashboard/SUBSCRIBE_DASHBOARD_UPDATES';
export const UNSUBSCRIBE_DASHBOARD_UPDATES = 'dashboard/UNSUBSCRIBE_DASHBOARD_UPDATES';
export const SYNC_DASHBOARD_UPDATE = 'dashboard/SYNC_DASHBOARD_UPDATE';
export const API_ERROR = 'dashboard/API_ERROR';
export const UPDATE_DASHBOARD_NAME = 'dashboard/UPDATE_DASHBOARD_NAME';

export const loadDashboard = (id?: string, email?: string) => ({
  type: LOAD_DASHBOARD_REQUEST,
  payload: {
    id,
    email,
  },
});

export const loadUserDashboardSuccess = (dashboard: Dashboard) => ({
  type: LOAD_USER_DASHBOARD_SUCCESS,
  payload: {
    dashboard,
  },
});

export const fetchAndLoadProject = (dashboardId: string) => ({
  type: FETCH_LOAD_PROJECT,
  payload: {
    dashboardId,
  },
});

export const dashboardLoaded = (dashboard: Dashboard) => ({
  type: LOAD_DASHBOARD_SUCCESS,
  payload: {
    dashboard,
  },
});

export const createDashboard = (name: string | null) => ({
  type: CREATE_DASHBOARD_REQUEST,
  payload: {
    name,
  },
});

export const createDashboardOptimistic = (dashboard: Dashboard) => ({
  type: CREATE_DASHBOARD_OPTIMISTIC,
  payload: {
    dashboard,
  },
});

export const addProjectLocal = (dashboardId: string, projectId: string) => ({
  type: ADD_PROJECT_LOCAL,
  payload: {
    dashboardId,
    projectId,
  },
});

export const addProject = (dashboardId: string, projectId: string) => ({
  type: ADD_PROJECT,
  payload: {
    dashboardId,
    projectId,
  },
});

//
export const addProjectOptimistic = (dashboardId: string, projectId: string) => ({
  type: ADD_PROJECT_OPTIMISTIC,
  payload: {
    dashboardId,
    projectId,
  },
});

export const addProjectSuccess = (dashboard: Dashboard) => ({
  type: ADD_PROJECT_SUCCESS,
  payload: {
    dashboard,
  },
});

export const collaborateProjectRequest = (dashboardId: string, projectId: string) => ({
  type: COLLABORATE_PROJECT_REQUEST,
  payload: {
    dashboardId,
    projectId,
  },
});

export const collaborateProjectSuccess = () => ({
  type: COLLABORATE_PROJECT_SUCCESS,
});

export const importProjectRequest = (dashboardId: string, files: string[]) => ({
  type: IMPORT_PROJECT_REQUEST,
  payload: {
    dashboardId,
    files,
  },
});

export const importProjectSuccess = () => ({
  type: IMPORT_PROJECT_SUCCESS,
});

export const addProjects = (dashboardId: string, projectIds: string[]) => ({
  type: ADD_PROJECTS,
  payload: {
    dashboardId,
    projectIds,
  },
});

export const addProjectsOptimistic = (dashboardId: string, projectIds: [string]) => ({
  type: ADD_PROJECTS_OPTIMISTIC,
  payload: {
    dashboardId,
    projectIds,
  },
});

export const addProjectsSuccess = (dashboard: Dashboard) => ({
  type: ADD_PROJECTS_SUCCESS,
  payload: {
    dashboard,
  },
});

export const subscribeToDashboardUpdates = (dashboardId: string) => ({
  type: SUBSCRIBE_DASHBOARD_UPDATES,
  payload: {
    dashboardId,
  },
});

export const unsubscribeToDashboardUpdates = () => ({
  type: UNSUBSCRIBE_DASHBOARD_UPDATES,
});

export const syncDashboardUpdate = (dashboard: Dashboard) => ({
  type: SYNC_DASHBOARD_UPDATE,
  payload: {
    dashboard,
  },
});

export const apiError = (error: any) => ({
  type: API_ERROR,
  payload: {
    error,
  },
});

export const updateDashboardName = (id: string, name: string) => ({
  type: UPDATE_DASHBOARD_NAME,
  payload: {
    id,
    name,
  },
});
