import {
  API_ERROR,
  UPDATE_PROJECT_NAME,
  REMOVE_PROJECT_REFERENCE,
  CREATE_PROJECT_OPTIMISTIC,
  REFRESH_PROJECT_SUCCESS,
  LOAD_ALL_PROJECTS_SUCCESS,
  DELETE_PROJECT_OPTIMISTIC,
  REMOVE_DOCUMENT_OPTIMISTIC,
  CREATE_DOCUMENT,
  ADD_PROJECT_REFERENCE_OPTIMISTIC,
  REMOVE_PROJECT_REFERENCE_OPTIMISTIC,
  UPDATE_ACTIVE_PROJECT_ID,
  UPDATE_ACTIVE_WORKSPACE_ID,
  DOCUMENT_DRAG_DROP,
  COPY_PROJECT_OPTIMISTIC,
  UPDATE_PARENT_PROJECT_ID,
  LOAD_PROJECT_SUCCESS,
  UPDATE_DOCUMENT_DESC_OPTIMISTIC,
  SYNC_PROJECT_UPDATE,
  SYNC_PROJECT_REMOVE,
  UPDATE_ROOT_PROJECT_ID_OPTIMISTIC,
  UPDATE_PROJECT_TREE_ROOT_ID,
  LINK_PROJECT_OPTIMISTIC,
  UPDATE_DOCUMENT_NAME_OPTIMISTIC,
  LOAD_PROJECT_REQUEST,
  LOAD_ALL_PROJECTS,
  LOAD_ALL_SUB_PROJECTS_SUCCESS,
  UPDATE_NESTED_PARENTS,
  PROJECT_FLOW,
  PROJECT_HIERARCHY_ROOT_PROJECT_ID,
  HIDE_PARENT_LINK,
  REMOVE_PROJECT_OPTIMISTIC,
  RESTORE_DOCUMENT_OPTIMISTIC,
  RESTORE_PROJECT_OPTIMISTIC,
} from './actions';
import * as _ from 'lodash';
import { UPDATE_ACCESS_CONTROL } from '../access';

export interface RuleSuite {
  id: string;
  type: 'project' | 'workspace';
  name?: string;
  desc?: string;
  seqNo: number;
  isLinked: boolean;
  removedAt?: Date | null;
}

export interface Project {
  id: string;
  name: string;
  isLinked: boolean;
  linkCount: number;
  ruleSuites: RuleSuite[];
  rootProjectId: string;
  createdDate: Date;
  createdBy?: string;
  removedAt?: Date | null;
  access: any;
}

export interface ProjectState {
  projectTreeRootId: string;
  parentProjectId: string;
  activeProjectId: string;
  activeWorkspaceId: string;
  nestedParents: Project[];
  ParentLinksToHide: string[];
  allProjects: Project[];
  projectFlow: string;
  projectHierRootProjectId: string;
  projectMap: {
    [projectId: string]: Project;
  };
  error: object | undefined;
  loading: boolean;
  docUploadFlag: boolean;
}

export interface Action {
  type: string;
  payload?: any;
}
const initialProject: Project = {
  id: '',
  name: '',
  isLinked: false,
  linkCount: 0,
  ruleSuites: [],
  rootProjectId: '',
  createdDate: new Date(),
  access: null,
};

const initialState: ProjectState = {
  projectTreeRootId: '',
  parentProjectId: '',
  activeProjectId: '',
  activeWorkspaceId: '',
  allProjects: [],
  projectFlow: '',
  projectHierRootProjectId: '',
  nestedParents: [],
  ParentLinksToHide: [],
  projectMap: {},
  loading: false,
  error: undefined,
  docUploadFlag: true,
};

export const projectReducer = (state: ProjectState = initialState, action: Action): ProjectState => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_PROJECT_OPTIMISTIC: {
      const { project } = payload;
      return {
        ...state,
        parentProjectId: '',
        activeProjectId: project.id,
        activeWorkspaceId: '',
        projectMap: {
          ...state.projectMap,
          [project.id]: {
            ...initialProject,
            id: project.id,
            name: project.name,
            isLinked: project.isLinked,
            linkCount: 0,
            ruleSuites: project.ruleSuites,
            rootProjectId: project.rootProjectId,
            createdDate: project.createdDate,
            removedAt: project.removedAt,
          },
        },
      };
    }
    case COPY_PROJECT_OPTIMISTIC: {
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
          [payload.project.id]: {
            ...initialProject,
            id: payload.project.id,
            name: payload.project.name,
            isLinked: payload.project.isLinked,
            linkCount: payload.project.linkCount,
            ruleSuites: payload.project.ruleSuites,
            rootProjectId: payload.project.rootProjectId,
            createdDate: new Date(),
            removedAt: payload.project.removedAt,
          },
        },
      };
    }

    case LOAD_ALL_PROJECTS:
    case LOAD_PROJECT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }

    case SYNC_PROJECT_UPDATE:
    case LOAD_PROJECT_SUCCESS: {
      const { project } = payload;
      return {
        ...state,
        projectTreeRootId:
          project.rootProjectId && project.rootProjectId.length > 0 ? project.rootProjectId : project.id,
        activeProjectId: project.id,
        loading: false,
        error: undefined,
        projectMap: {
          ...state.projectMap,
          [project.id]: {
            ...state.projectMap[project.id],
            id: payload.project.id,
            name: payload.project.name,
            isLinked: payload.project.isLinked,
            linkCount: payload.project.linkCount,
            ruleSuites: payload.project.ruleSuites,
            rootProjectId: payload.project.rootProjectId,
            createdDate: new Date(),
            createdBy: payload.project.createdBy,
            removedAt: payload.project.removedAt,
          },
        },
      };
    }

    case LOAD_ALL_PROJECTS_SUCCESS: {
      const projects = _.flattenDeep(payload);
      const projectsKeys = Object.keys(_.flattenDeep(projects));
      const loadedMap: any = {};
      projectsKeys.length > 0 &&
        projectsKeys.map((key: any) => {
          const project: any = projects[key];
          loadedMap[project.id] = {
            ...project,
          };
        });
      return {
        ...state,
        loading: false,
        projectMap: {
          ...state.projectMap,
          ...loadedMap,
        },
      };
    }

    case REFRESH_PROJECT_SUCCESS: {
      const newRuleSuiteToUpdate = payload.project.ruleSuites.filter(
        (payload_rs: RuleSuite) =>
          !state.projectMap[payload.project.id].ruleSuites.some((state_rs: RuleSuite) => payload_rs.id == state_rs.id)
      );
      return {
        ...state,
        activeProjectId: payload.project.id,
        activeWorkspaceId: payload.workspaceId && payload.workspaceId.length > 0 ? payload.workspaceId : null,
        projectMap: {
          ...state.projectMap,
          [payload.project.id]: {
            ...state.projectMap[payload.project.id],
            id: payload.project.id,
            name: payload.project.name,
            isLinked: payload.project.isLinked,
            linkCount: payload.project.linkCount,
            createdBy: payload.project.createdBy,
            ruleSuites: [...state.projectMap[payload.project.id].ruleSuites, ...newRuleSuiteToUpdate],
            rootProjectId: payload.project.rootProjectId,
            createdDate: new Date(),
            removedAt: payload.project.removedAt,
          },
        },
      };
    }
    case UPDATE_PROJECT_NAME: {
      const { projectId, name } = payload;
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
          [projectId]: {
            ...state.projectMap[projectId],
            name: name,
            isLinked: (state.projectMap[projectId] && state.projectMap[projectId].isLinked) || false,
            linkCount: (state.projectMap[projectId] && state.projectMap[projectId].linkCount) || 0,
            rootProjectId: state.projectMap[projectId] && state.projectMap[projectId].rootProjectId,
            createdDate: new Date(),
            removedAt: state.projectMap[projectId] && state.projectMap[projectId].removedAt,
          },
        },
      };
    }

    case UPDATE_ROOT_PROJECT_ID_OPTIMISTIC: {
      const { projectId, rootProjectId } = payload;
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
          [projectId]: {
            ...state.projectMap[projectId],
            rootProjectId: rootProjectId,
          },
        },
      };
    }

    case UPDATE_ACCESS_CONTROL: {
      const { projectId, publicAccess } = payload;
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
          [projectId]: {
            ...state.projectMap[projectId],
            publicAccess,
          },
        },
      };
    }

    case UPDATE_PROJECT_TREE_ROOT_ID: {
      return {
        ...state,
        projectTreeRootId: payload.projectId,
      };
    }

    case PROJECT_FLOW: {
      const { flow } = payload;
      return {
        ...state,
        projectFlow: flow,
      };
    }

    case PROJECT_HIERARCHY_ROOT_PROJECT_ID: {
      const { projectId } = payload;
      return {
        ...state,
        projectHierRootProjectId: projectId,
      };
    }

    case UPDATE_ACTIVE_PROJECT_ID: {
      return {
        ...state,
        activeProjectId: payload.projectId,
      };
    }

    case UPDATE_PARENT_PROJECT_ID: {
      return {
        ...state,
        parentProjectId: payload.projectId,
      };
    }

    case UPDATE_ACTIVE_WORKSPACE_ID: {
      return {
        ...state,
        activeWorkspaceId: payload.workspaceId,
      };
    }

    case UPDATE_NESTED_PARENTS: {
      return {
        ...state,
        nestedParents: payload.projects,
      };
    }

    case HIDE_PARENT_LINK: {
      const { projectIds } = payload;
      return {
        ...state,
        ParentLinksToHide: projectIds,
      };
    }

    case DELETE_PROJECT_OPTIMISTIC: {
      Object.keys(state.projectMap).forEach((projectId) => {
        const updatedRuleSuite: RuleSuite[] = state.projectMap[projectId].ruleSuites.filter(
          (ruleSuite) => ruleSuite.id !== payload.projectId
        );
        state.projectMap[projectId].ruleSuites = updatedRuleSuite;
      });
      const updatedProjectMap: any = _.omit(state.projectMap, payload.projectId);
      return {
        ...state,
        projectMap: {
          ...updatedProjectMap,
        },
      };
    }

    case SYNC_PROJECT_REMOVE:
    case REMOVE_PROJECT_OPTIMISTIC: {
      Object.keys(state.projectMap).forEach((id) => {
        if (id === payload.projectId) {
          state.projectMap[id].removedAt = new Date();
        } else {
          state.projectMap[id].ruleSuites.map((ruleSuite: RuleSuite) => {
            if (ruleSuite.type === 'project' && ruleSuite.id === payload.projectId && !ruleSuite.removedAt) {
              ruleSuite.removedAt = new Date();
            }
          });
        }
      });
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
        },
      };
    }

    case RESTORE_PROJECT_OPTIMISTIC: {
      Object.keys(state.projectMap).forEach((id) => {
        if (id === payload.projectId) {
          state.projectMap[id].removedAt = null;
        } else {
          state.projectMap[id].ruleSuites.map((ruleSuite: RuleSuite) => {
            if (ruleSuite.type === 'project' && ruleSuite.id === payload.projectId) {
              ruleSuite.removedAt = null;
            }
          });
        }
      });
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
        },
      };
    }

    case CREATE_DOCUMENT: {
      return {
        ...state,
        activeProjectId: payload.projectId,
        activeWorkspaceId: '',
        projectMap: {
          ...state.projectMap,
        },
      };
    }
    case LINK_PROJECT_OPTIMISTIC: {
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
          [payload.projectId]: {
            ...state.projectMap[payload.projectId],
            isLinked: true,
          },
        },
      };
    }

    case DOCUMENT_DRAG_DROP: {
      const { projectId, startIndex, destinationIndex } = payload;
      const selectedProject = state.projectMap[projectId];
      const newRuleSuites: RuleSuite[] = Array.from(selectedProject.ruleSuites);
      const tempRuleSuite: RuleSuite[] = newRuleSuites.splice(startIndex, 1);
      const sourceRuleSuite: RuleSuite = tempRuleSuite[0];
      sourceRuleSuite.seqNo = destinationIndex + 1;
      newRuleSuites.splice(destinationIndex, 0, sourceRuleSuite);

      for (let i = destinationIndex + 1; i < newRuleSuites.length; i++) {
        newRuleSuites[i].seqNo = newRuleSuites[i].seqNo + 1;
      }
      const newProject = {
        ...selectedProject,
        ruleSuites: newRuleSuites,
      };
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
          [projectId]: {
            ...newProject,
          },
        },
      };
    }

    case REMOVE_DOCUMENT_OPTIMISTIC: {
      const { workspaceId, projectId } = payload;
      state.projectMap[projectId].ruleSuites.map((ruleSuite: RuleSuite) => {
        if (ruleSuite.id === workspaceId) {
          ruleSuite.removedAt = new Date();
        }
      });
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
          [projectId]: {
            ...state.projectMap[projectId],
          },
        },
      };
    }

    case RESTORE_DOCUMENT_OPTIMISTIC: {
      const { workspaceId, projectId } = payload;
      // restore document
      state.projectMap[projectId].ruleSuites.map((ruleSuite: RuleSuite) => {
        if (ruleSuite.id === workspaceId) {
          ruleSuite.removedAt = null;
        }
      });
      // check enclosing project and restore it if its removed leaving all other documents as 'removed'
      const enclosingProject = state.projectMap[projectId];
      if (enclosingProject.removedAt) {
        state.projectMap[projectId].removedAt = null;
        state.projectMap[projectId].ruleSuites.map((ruleSuite: RuleSuite) => {
          if (ruleSuite.id !== workspaceId && !ruleSuite.removedAt) {
            ruleSuite.removedAt = new Date();
          }
        });
      }
      console.log('encosing parent', enclosingProject);
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
          [projectId]: {
            ...state.projectMap[projectId],
          },
        },
      };
    }

    case ADD_PROJECT_REFERENCE_OPTIMISTIC: {
      state.projectMap[payload.currentProjectId].ruleSuites.push({
        id: payload.refProjectId,
        type: 'project',
        name: state.projectMap[payload.currentProjectId].name,
        desc: '',
        seqNo: state.projectMap[payload.currentProjectId].ruleSuites.length + 1,
        isLinked: true,
      });
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
          [payload.currentProjectId]: {
            ...state.projectMap[payload.currentProjectId],
          },
          [payload.refProjectId]: {
            ...state.projectMap[payload.refProjectId],
            isLinked: true,
            linkCount: state.projectMap[payload.refProjectId].linkCount + 1,
          },
        },
      };
    }

    case UPDATE_DOCUMENT_DESC_OPTIMISTIC: {
      const { projectId, documentId, documentDesc } = payload;
      state.projectMap[projectId].ruleSuites.map((ruleSuite: RuleSuite) => {
        return ruleSuite.type === 'workspace' && ruleSuite.id === documentId
          ? (ruleSuite.desc = documentDesc)
          : ruleSuite.desc;
      });
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
          [projectId]: {
            ...state.projectMap[projectId],
          },
        },
      };
    }

    case UPDATE_DOCUMENT_NAME_OPTIMISTIC: {
      const { projectId, documentId, documentName } = payload;
      state.projectMap[projectId].ruleSuites.map((ruleSuite: RuleSuite) => {
        return ruleSuite.type === 'workspace' && ruleSuite.id === documentId
          ? (ruleSuite.name = documentName)
          : ruleSuite.name;
      });
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
          [projectId]: {
            ...state.projectMap[projectId],
          },
        },
      };
    }

    case REMOVE_PROJECT_REFERENCE: {
      return {
        ...state,
        loading: true,
      };
    }

    case REMOVE_PROJECT_REFERENCE_OPTIMISTIC: {
      const { refProjectId, currentProjectId } = payload;
      const updatedRuleSuite: RuleSuite[] = state.projectMap[currentProjectId].ruleSuites.filter(
        (ruleSuite) => ruleSuite.id !== refProjectId
      );
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
          [refProjectId]: {
            ...state.projectMap[refProjectId],
            linkCount: state.projectMap[payload.refProjectId].linkCount - 1,
            isLinked: state.projectMap[payload.refProjectId].linkCount - 1 <= 0 ? false : true,
          },

          [currentProjectId]: {
            ...state.projectMap[currentProjectId],
            ruleSuites: updatedRuleSuite,
          },
        },
      };
    }

    case LOAD_ALL_SUB_PROJECTS_SUCCESS: {
      const projects = _.flattenDeep(payload);
      const projectsKeys = Object.keys(_.flattenDeep(projects));
      const loadedMap: any = {};
      projectsKeys.length > 0 &&
        projectsKeys.map((key: any) => {
          const project: any = projects[key];
          loadedMap[project.id] = {
            ...project,
          };
        });
      return {
        ...state,
        loading: false,
        projectMap: {
          ...loadedMap,
        },
      };
    }

    case API_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    default:
      return state;
  }
};
