import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const logoClick = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Logo click',
  });
};

const bannerClick = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Banner click',
  });
};

const copyToClipboard = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Copy to clipboard',
  });
};

const exportAsText = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Export as text',
  });
};

const exportAsExcel = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Export as excel',
  });
};

const runReasoner = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Run reasoner',
  });
};

const wordwrapToggle = (value: number) => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Wordwrap toggle',
    value,
  });
};

const testCaseWordWrapToggle = (value: number) => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Wordwrap testcase toggle',
    value,
  });
};

const autoScrollToggle = (value: number) => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Auto scroll toggle',
    value,
  });
};

const syntaxHighlightToggle = (value: number) => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Syntax highlight toggle',
    value,
  });
};

const collapseSegment = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Collapse segment',
  });
};

const expandSegment = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Expand segment',
  });
};

const resizeSegment = (value: number) => {
  trackCustomEvent({
    category: 'UI',
    action: 'Resize segment',
    value,
  });
};

const deleteSegment = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Delete segment',
  });
};

const deleteSegmentConfirm = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Delete segment confirm',
  });
};

const deleteSegmentCancel = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Delete segment cancel',
  });
};

const splitPaneDrag = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Split pane drag',
  });
};

const uploadDocument = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Upload document',
  });
};

const segmentPanelClosed = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Segment panel closed',
  });
};

const expandAllSegments = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Expand all segments',
  });
};

const collapseAllSegments = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Collapse all segments',
  });
};

const segmentPanelOpened = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Segment panel opened',
  });
};

const resultsPanelOpened = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Results panel opened',
  });
};

const resultsPanelClosed = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Results panel closed',
  });
};

const rulePanelClosed = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Rule panel closed',
  });
};

const rulePanelOpened = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Rule panel opened',
  });
};

const testPanelClosed = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Test panel closed',
  });
};

const testPanelOpened = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Test panel opened',
  });
};

const splitPaneDoubleClick = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Split pane double click',
  });
};

const testDrawerOpened = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Test drawer opened',
  });
};

const createNewTestCase = (label: 'Toolbar' | 'Drawer Toolbar' | 'Empty Panel' | 'Empty Drawer') => {
  trackCustomEvent({
    category: 'UI',
    action: 'Create new test case',
    label,
  });
};

const descriptionToggle = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Description toggle',
  });
};

const factsToggle = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Facts toggle',
  });
};

const requiredResultsToggle = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Required results toggle',
  });
};

const unwantedResultsToggle = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Unwanted results toggle',
  });
};

const startWatchMode = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Start Watch mode',
  });
};

const stopWatchMode = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Stop watch mode',
  });
};

const deleteTestCase = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Delete test case',
  });
};

const deleteTestCaseConfirm = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Delete test case confirm',
  });
};

const deleteTestCaseCancel = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Delete test case cancel',
  });
};

const cloneTestCase = () => {
  trackCustomEvent({
    category: 'UI',
    action: 'Clone test case',
  });
};

const openScratchpad = () => {
  trackCustomEvent({
    category: 'UI - Toolbar',
    action: 'Open scratchpad',
  });
};

export const trackEvent = {
  copyToClipboard,
  exportAsText,
  exportAsExcel,
  logoClick,
  bannerClick,
  runReasoner,
  wordwrapToggle,
  testCaseWordWrapToggle,
  autoScrollToggle,
  syntaxHighlightToggle,
  collapseSegment,
  expandSegment,
  resizeSegment,
  deleteSegment,
  deleteSegmentCancel,
  deleteSegmentConfirm,
  splitPaneDrag,
  uploadDocument,
  segmentPanelClosed,
  segmentPanelOpened,
  rulePanelClosed,
  rulePanelOpened,
  testPanelClosed,
  testPanelOpened,
  splitPaneDoubleClick,
  resultsPanelOpened,
  resultsPanelClosed,
  testDrawerOpened,
  createNewTestCase,
  descriptionToggle,
  factsToggle,
  requiredResultsToggle,
  unwantedResultsToggle,
  startWatchMode,
  stopWatchMode,
  openScratchpad,
  deleteTestCase,
  deleteTestCaseConfirm,
  deleteTestCaseCancel,
  cloneTestCase,
  expandAllSegments,
  collapseAllSegments,
};
