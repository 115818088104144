import { apollo } from '../apollo/client';
import gql from 'graphql-tag';
import { Segment } from '../store/segment';
import { Workspace } from '../store/workspace';

const CREATE_WORKSPACE_QUERY = gql`
  mutation ($file: Upload!) {
    createWorkspace(file: $file) {
      id
      convertedHTML
      legalTextCount
      name
      desc
      urlPath
      migrated
    }
  }
`;

const createWorkspace = async (file: File) => {
  const { data } = await apollo.mutate({
    mutation: CREATE_WORKSPACE_QUERY,
    variables: {
      file,
    },
  });

  return { ...data.createWorkspace };
};

const CRAETE_WORKSPACE_FOR_PROJECT = gql`
  mutation CRAETE_WORKSPACE_FOR_PROJECT($workspaceInputType: WorkspaceInput!) {
    createWorkspaceForProject(workspaceInputType: $workspaceInputType) {
      id
      convertedHTML
      legalTextCount
      name
      desc
      urlPath
      migrated
    }
  }
`;

const createWorkspaceForProject = async ({ id, convertedHTML, legalTextCount, name, desc }: Workspace) => {
  const { data } = await apollo.mutate({
    mutation: CRAETE_WORKSPACE_FOR_PROJECT,
    variables: {
      workspaceInputType: {
        id,
        convertedHTML,
        legalTextCount,
        name,
        desc,
      },
    },
  });
  return { ...data.createWorkspaceForProject } as Workspace;
};

const UPDATE_WORKSPACE_QUERY = gql`
  mutation UPDATE_WORKSPACE($workspaceInput: WorkspaceInput!) {
    updateWorkspace(workspaceInput: $workspaceInput) {
      id
      name
      desc
      urlPath
      migrated
    }
  }
`;
const updateWorkspace = async ({ id, convertedHTML, legalTextCount, name, desc }: Workspace) => {
  const { data } = await apollo.mutate({
    mutation: UPDATE_WORKSPACE_QUERY,
    variables: {
      workspaceInput: {
        id,
        convertedHTML,
        legalTextCount,
        name,
        desc,
      },
    },
  });
  return { ...data.updateWorkspace } as Workspace;
};

const GET_WORKSPACE_QUERY = gql`
  query GET_WORKSPACE($id: ID!) {
    workspace(id: $id) {
      id
      convertedHTML
      legalTextCount
      name
      desc
      urlPath
      migrated
      segments {
        id
        workspaceId
        type
        startLegalTextId
        endLegalTextId
        rule
        version
      }
      testCases {
        id
        workspaceId
        title
        description
        facts
        requiredResults
        unwantedResults
        version
      }
    }
  }
`;

const getWorkspace = async (id: string) => {
  const { data } = await apollo.mutate({
    mutation: GET_WORKSPACE_QUERY,
    variables: {
      id,
    },
  });
  if (!data.workspace) {
    throw `Workspace ${id} not found`;
  }
  return { ...data.workspace };
};

const SEGMENT_BY_WORKSPACE_ID_QUERY = gql`
  query SEGMENT_BY_WORKSPACE_ID($workspaceId: String!) {
    segmentsByWorkspaceId(workspaceId: $workspaceId) {
      id
      workspaceId
      type
      rule
      startLegalTextId
      endLegalTextId
      version
    }
  }
`;

const getAllSegmentsByWorkspaceId = async (workspaceId: string) => {
  const { data } = await apollo.mutate({
    mutation: SEGMENT_BY_WORKSPACE_ID_QUERY,
    variables: { workspaceId },
  });
  return { ...data.segmentsByWorkspaceId };
};

const CREATE_SEGMENT_QUERY = gql`
  mutation CREATE_SEGMENT($input: SegmentInput!) {
    createSegment(segmentInput: $input) {
      id
      workspaceId
      type
      startLegalTextId
      endLegalTextId
      rule
      version
    }
  }
`;

const createSegment = async ({ id, workspaceId, type, startLegalTextId, endLegalTextId, rule, version }: Segment) => {
  const { data } = await apollo.mutate({
    mutation: CREATE_SEGMENT_QUERY,
    variables: {
      input: {
        id,
        workspaceId,
        type,
        startLegalTextId,
        endLegalTextId,
        rule,
        version,
      },
    },
  });

  return { ...data.createSegment } as Segment;
};

const SAVE_SEGMENT_QUERY = gql`
  mutation SAVE_SEGMENT($input: SegmentInput!) {
    saveSegment(segmentInput: $input) {
      id
      workspaceId
      type
      startLegalTextId
      endLegalTextId
      rule
      version
    }
  }
`;

const saveSegment = async ({ id, workspaceId, type, startLegalTextId, endLegalTextId, rule, version }: Segment) => {
  const { data } = await apollo.mutate({
    mutation: SAVE_SEGMENT_QUERY,
    variables: {
      input: {
        id,
        workspaceId,
        type,
        startLegalTextId,
        endLegalTextId,
        rule,
        version,
      },
    },
  });
  return { ...data.saveSegment } as Segment;
};

const DELETE_SEGMENT_QUERY = gql`
  mutation DELETE_SEGMENT($id: ID!) {
    deleteSegment(id: $id) {
      segment {
        id
      }
    }
  }
`;
const deleteSegment = async (id: string) => {
  const { data } = await apollo.mutate({
    mutation: DELETE_SEGMENT_QUERY,
    variables: {
      id,
    },
  });
  return (data && data.deleteSegment.segment && data.deleteSegment.segment.id) || '';
};

export const workspaceService = {
  createWorkspace,
  createWorkspaceForProject,
  updateWorkspace,
  getWorkspace,
  getAllSegmentsByWorkspaceId,
  createSegment,
  saveSegment,
  deleteSegment,
};
