import {
  CREATE_WORKSPACE_SUCCESS,
  CREATE_WORKSPACE_REQUEST,
  API_ERROR,
  LOAD_WORKSPACE_SUCCESS,
  LOAD_WORKSPACE_REQUEST,
  UPDATE_WORKSPACE_DESC_OPTIMISTIC,
  WORKSPACE_READ_MODE,
  ADD_WORKSPACE_SUCCESS,
  UPDATE_WORKSPACE_NAME_OPTIMISTIC,
} from './actions';

export interface Workspace {
  id: string;
  convertedHTML: string;
  legalTextCount: number;
  name: string;
  desc: string;
  migrated: boolean;
  urlPath: string;
}

export interface WorkspaceState {
  currentWorkspaceId: string;
  workspaceReadMode: boolean;
  workspacesMap: {
    [workspaceId: string]: {
      id: string;
      convertedHTML: string;
      legalTextCount: number;
      name: string;
      desc: string;
      migrated: boolean;
      urlPath: string;
    };
  };
  error: object | undefined;
  loading: boolean;
}

const initialState: WorkspaceState = {
  currentWorkspaceId: '',
  workspacesMap: {},
  workspaceReadMode: false,
  loading: false,
  error: undefined,
};

export interface Action {
  type: string;
  payload?: any;
}

export const workspaceReducer = (state: WorkspaceState = initialState, action: Action): WorkspaceState => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_WORKSPACE_REQUEST:
    case LOAD_WORKSPACE_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case CREATE_WORKSPACE_SUCCESS:
      const docName = (payload.name.indexOf('.') > 0 && payload.name.split('.')[0]) || payload.name;
      return {
        ...state,
        loading: false,
        error: undefined,
        currentWorkspaceId: payload.id,
        workspacesMap: {
          ...state.workspacesMap,
          [payload.id]: {
            id: payload.id,
            convertedHTML: payload.convertedHTML,
            legalTextCount: payload.legalTextCount,
            name: docName,
            desc: payload.desc,
          },
        },
      };
    case LOAD_WORKSPACE_SUCCESS:
      const styledConvertedHTML =
        '<pre style = "font-family: Montserrat,Roboto Mono,Arial,sans-serif; font-weight: 400;line-height: 1.43;font-size: 1rem; white-space: pre-wrap;">' +
        payload.convertedHTML +
        '</pre>';
      return {
        ...state,
        loading: false,
        error: undefined,
        currentWorkspaceId: payload.id,
        workspacesMap: {
          ...state.workspacesMap,
          [payload.id]: {
            ...state.workspacesMap[payload.id],
            name: payload.name,
            desc: payload.desc,
            convertedHTML: styledConvertedHTML,
            legalTextCount: payload.legalTextCount,
            migrated: payload.migrated,
            urlPath: payload.urlPath,
          },
        },
      };

    case UPDATE_WORKSPACE_DESC_OPTIMISTIC: {
      return {
        ...state,
        workspacesMap: {
          ...state.workspacesMap,
          [payload.workspace.id]: {
            ...state.workspacesMap[payload.id],
            id: payload.workspace.id,
            name: payload.workspace.name,
            convertedHTML: payload.workspace.convertedHTML,
            legalTextCount: payload.workspace.legalTextCount,
            migrated: payload.workspace.migrated,
            urlPath: payload.workspace.urlPath,
            desc: payload.desc,
          },
        },
      };
    }
    case UPDATE_WORKSPACE_NAME_OPTIMISTIC: {
      return {
        ...state,
        workspacesMap: {
          ...state.workspacesMap,
          [payload.workspace.id]: {
            ...state.workspacesMap[payload.id],
            id: payload.workspace.id,
            desc: payload.workspace.desc,
            convertedHTML: payload.workspace.convertedHTML,
            legalTextCount: payload.workspace.legalTextCount,
            migrated: payload.workspace.migrated,
            urlPath: payload.workspace.urlPath,
            name: payload.name,
          },
        },
      };
    }
    case ADD_WORKSPACE_SUCCESS:
      const { id, convertedHTML, legalTextCount, name, desc } = payload;
      return {
        ...state,
        currentWorkspaceId: payload.id,
        workspacesMap: {
          ...state.workspacesMap,
          [payload.id]: {
            ...state.workspacesMap[payload.id],
            id: id,
            convertedHTML: convertedHTML,
            legalTextCount: legalTextCount,
            name: name,
            desc: desc,
          },
        },
      };
    case WORKSPACE_READ_MODE: {
      const { flag } = payload;
      return {
        ...state,
        workspaceReadMode: flag,
      };
    }

    case API_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    default:
      return state;
  }
};
