import {
  CREATE_DASHBOARD_OPTIMISTIC,
  API_ERROR,
  ADD_PROJECT_OPTIMISTIC,
  LOAD_DASHBOARD_SUCCESS,
  LOAD_DASHBOARD_REQUEST,
  SYNC_DASHBOARD_UPDATE,
  UPDATE_DASHBOARD_NAME,
  ADD_PROJECTS_OPTIMISTIC,
  COLLABORATE_PROJECT_REQUEST,
  COLLABORATE_PROJECT_SUCCESS,
  LOAD_USER_DASHBOARD_SUCCESS,
  ADD_PROJECT_LOCAL,
  IMPORT_PROJECT_REQUEST,
  IMPORT_PROJECT_SUCCESS,
} from './actions';

export interface Dashboard {
  id: string;
  name: string;
  projects: string[];
}

export interface DashboardState {
  activeDashboardId: string;
  activeUserEmailId: string;
  dashboardMap: {
    [dashboardId: string]: Dashboard;
  };
  error: Error | undefined;
  loading: boolean;
}

export interface Action {
  type: string;
  payload?: any;
}

const initialDashboard: Dashboard = {
  id: '',
  name: '',
  projects: [],
};

const initialState: DashboardState = {
  activeUserEmailId: '',
  activeDashboardId: '',
  dashboardMap: {},
  error: undefined,
  loading: false,
};

export const dashboardReducer = (state: DashboardState = initialState, action: Action): DashboardState => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_DASHBOARD_REQUEST: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }

    case LOAD_USER_DASHBOARD_SUCCESS:
    case SYNC_DASHBOARD_UPDATE:
    case LOAD_DASHBOARD_SUCCESS: {
      const { dashboard } = payload;

      return {
        ...state,
        loading: false,
        error: undefined,
        activeDashboardId: (type !== LOAD_USER_DASHBOARD_SUCCESS && dashboard.id) || null,
        activeUserEmailId: (type === LOAD_USER_DASHBOARD_SUCCESS && dashboard.id) || null,
        dashboardMap: {
          ...state.dashboardMap,
          [dashboard.id]: {
            id: dashboard.id,
            name: dashboard.name,
            projects: dashboard.projects,
          },
        },
      };
    }

    case CREATE_DASHBOARD_OPTIMISTIC: {
      const { dashboard } = payload;
      return {
        ...state,
        activeDashboardId: dashboard.id,
        dashboardMap: {
          ...state.dashboardMap,
          [dashboard.id]: {
            ...initialDashboard,
            id: dashboard.id,
            name: dashboard.name,
            projects: [],
          },
        },
      };
    }

    case ADD_PROJECT_LOCAL:
    case ADD_PROJECT_OPTIMISTIC: {
      const { projectId } = payload;
      const availableProjects =
        (state.dashboardMap[payload.dashboardId] && state.dashboardMap[payload.dashboardId].projects) || [];
      availableProjects.push(projectId);
      return {
        ...state,
        dashboardMap: {
          ...state.dashboardMap,
          [payload.dashboardId]: {
            ...state.dashboardMap[payload.dashboardId],
            projects: availableProjects,
          },
        },
      };
    }

    case ADD_PROJECTS_OPTIMISTIC: {
      const { projectIds } = payload;
      const availableProjects =
        (state.dashboardMap[payload.dashboardId] && state.dashboardMap[payload.dashboardId].projects) || [];
      availableProjects.push(...projectIds);
      return {
        ...state,
        dashboardMap: {
          ...state.dashboardMap,
          [payload.dashboardId]: {
            ...state.dashboardMap[payload.dashboardId],
            projects: availableProjects,
          },
        },
      };
    }

    case UPDATE_DASHBOARD_NAME: {
      const { id, name } = payload;
      return {
        ...state,
        dashboardMap: {
          ...state.dashboardMap,
          [id]: {
            ...state.dashboardMap[id],
            name,
          },
        },
      };
    }

    case COLLABORATE_PROJECT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }

    case COLLABORATE_PROJECT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case IMPORT_PROJECT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }

    case IMPORT_PROJECT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case API_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    default:
      return state;
  }
};
