import codemirror from 'codemirror';
import './turnipStyles.css';

/**
 * Develop syntax highlighter to decorate special keyword with dedicated style. The stylesheet is attached in the JIRA.
 *
 */
codemirror.defineMode('turnipMode', function () {
  const variables =
    /^Atom$|^Numeric$|^Duration$|^String$|^Date$|^DateTime$|^interval$|^template$|^toHours$|^toDays$|^toWeeks$|^asMinutes$|^asHours$|^asDays$|^asWeeks$/;
  const namespaceVariables = /^namespace$|^closespace$/;
  const operators = /^(&)$|^(->)$|^(:=)$|^(>=)$|^(<=)$|^(\$)$|^(:\-)$|^(==)$|^(!=)$|^(>)$|^(\|)$/;
  const ruleType = /^-(strict|undercut)(->)$|^(=>)$/;
  const ruleLabel = /^(\w)(\.*\w)*:$|^(>>)$/;
  const startFunctionType = /^(\w*)\(()$/;

  const endFunctionType = /\){1}$/;

  const matchPattern = (type: any) => {
    let prev;
    prev = false;
    return function (c: any) {
      prev = c;
      if (c === type) {
        return prev === '\\';
      }
      return true;
    };
  };

  const readWordAndAdvanceCursorTillCondition = (stream: any, state: any) => {
    let ch;
    stream.backUp(1);
    if (ch == '\\') {
      stream.next();
    }
    while ((ch = stream.next())) {
      if (ch.match(/^(\w*)\(()$/)) {
        break;
      } else if (ch == ')' && state.openBracketCount > 0) {
        break;
      } else if (stream.peek() == ')') {
        break;
      } else if (ch == '~') {
        break;
      } else if (ch.match(/^\s/)) {
        stream.backUp(1);
        break;
      }
    }
    return stream.current();
  };

  const base = (stream: any, state: any) => {
    if (stream.eatSpace()) {
      return null;
    }
    let ch = stream.next();
    if (ch == '\\') {
      ch = stream.next();
    }
    if (ch == '/' && stream.peek() == '/') {
      stream.skipToEnd();
      return 'comments';
    } else if (ch == '"') {
      stream.eatWhile(matchPattern(ch));
      stream.next();
      return 'description';
    } else if (ch == '[') {
      stream.eatWhile(matchPattern(']'));
      stream.next();
      return 'modalities';
    } else if (ch == '~') {
      return 'operators';
    } else {
      const name = readWordAndAdvanceCursorTillCondition(stream, state);
      if (ruleType.test(name)) {
        return 'rule-types';
      } else if (operators.test(name)) {
        return 'operators';
      } else if (ruleLabel.test(name)) {
        return 'label';
      } else if (variables.test(name) && stream.start == 0) {
        return 'variables';
      } else if (namespaceVariables.test(name) && stream.start == 0) {
        return 'namespaceVariables';
      } else if (startFunctionType.test(name)) {
        state.openBracketCount += 1;
        return 'variables';
      } else if (endFunctionType.test(name) && state.openBracketCount > 0) {
        state.openBracketCount -= 1;
        return 'variables';
      } else {
        return 'text';
      }
    }
  };

  return {
    startState: function () {
      return {
        openBracketCount: 0,
        tokenize: base,
      };
    },
    token: (stream, state) => {
      const style = state.tokenize(stream, state);
      return style;
    },
  };
});
