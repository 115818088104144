import { ProjectState, RuleSuite, Project } from './reducer';
import { createSelector } from 'reselect';
import * as _ from 'lodash';

const projectState = (state: any) => {
  return state.project as ProjectState;
};

const projectTreeRootId = createSelector(projectState, ({ projectTreeRootId }) => projectTreeRootId);

const parentProjectId = createSelector(projectState, ({ parentProjectId }) => parentProjectId);

const activeProjectId = createSelector(projectState, ({ activeProjectId }) => activeProjectId);

const activeWorkspaceId = createSelector(projectState, ({ activeWorkspaceId }) => activeWorkspaceId);

const nestedParents = createSelector(projectState, ({ nestedParents }) => nestedParents);

const isLoading = createSelector(projectState, ({ loading }) => loading);

const activeProject = createSelector(projectState, activeProjectId, (state: ProjectState, activeProjectId: string) => {
  return state.projectMap[activeProjectId] || {};
});

const rootProjectId = (projectId: string) => {
  return createSelector(
    projectState,
    ({ projectMap }: ProjectState) => (projectMap[projectId] && projectMap[projectId].rootProjectId) || ''
  );
};

const createdBy = (projectId: string) => {
  return createSelector(
    projectState,
    ({ projectMap }: ProjectState) => (projectMap[projectId] && projectMap[projectId].createdBy) || ''
  );
};

// @ts-ignore
const projectName = createSelector(activeProject, ({ name }) => name);

// @ts-ignore
const ruleSuites = createSelector(activeProject, (project) => project.ruleSuites);

const projectById = (projectId: string) => {
  return createSelector(projectState, ({ projectMap }: ProjectState) => {
    return projectMap[projectId] || {};
  });
};

const projectNameById = (projectId: string) => {
  return createSelector(projectState, ({ projectMap }: ProjectState) => projectMap[projectId].name || '');
};

const documentNameById = (projectId: string, documentId: string) => {
  return createSelector(
    projectState,
    ({ projectMap }: ProjectState) =>
      projectMap[projectId].ruleSuites.filter(
        (ruleSuite: RuleSuite) => ruleSuite.type === 'workspace' && ruleSuite.id === documentId
      )[0].name
  );
};

const documentDescById = (projectId: string, documentId: string) => {
  return createSelector(
    projectState,
    ({ projectMap }: ProjectState) =>
      projectMap[projectId].ruleSuites.filter(
        (ruleSuite: RuleSuite) => ruleSuite.type === 'workspace' && ruleSuite.id === documentId
      )[0].desc
  );
};

const allProjects = createSelector(projectState, ({ projectMap }: ProjectState) => {
  return Object.keys(projectMap).map((id) => projectMap[id]);
});

const isDocUploadFlag = createSelector(projectState, ({ docUploadFlag }) => docUploadFlag);

const projectFlow = createSelector(projectState, ({ projectFlow }) => projectFlow);

const ParentLinksToHide = createSelector(projectState, ({ ParentLinksToHide }) => ParentLinksToHide);

const projectHierRootProjectId = createSelector(
  projectState,
  ({ projectHierRootProjectId }) => projectHierRootProjectId
);
const allWorkspaceIdName = (project: Project) => {
  return createSelector(projectState, ({ projectMap }: ProjectState) => {
    return (
      !_.isNil(project) &&
      project.ruleSuites &&
      project.ruleSuites.length > 0 &&
      project.ruleSuites.reduce((accumulator, ruleSuite) => {
        accumulator.push(fetchWorkspace(ruleSuite, projectMap));
        return accumulator;
      }, [] as any)
    );
  });
};

function fetchWorkspace(ruleSuite: RuleSuite, projectMap: any) {
  return ruleSuite.type === 'workspace'
    ? { id: ruleSuite.id, name: ruleSuite.name }
    : projectMap[ruleSuite.id] &&
        projectMap[ruleSuite.id].ruleSuites.length > 0 &&
        projectMap[ruleSuite.id].ruleSuites.reduce((accumulator: any, ruleSuite: RuleSuite) => {
          accumulator.push(fetchWorkspace(ruleSuite, projectMap));
          return accumulator;
        }, [] as any);
}

const allNestedSubProjectIds = (project: Project) => {
  return createSelector(projectState, ({ projectMap }: ProjectState) => {
    return (
      !_.isNil(project) &&
      project.ruleSuites &&
      project.ruleSuites.length > 0 &&
      project.ruleSuites
        .filter((ruleSuite: RuleSuite) => ruleSuite.type === 'project')
        .reduce((accumulator, ruleSuite) => {
          accumulator.push({
            id: ruleSuite.id,
            name: ruleSuite.name,
          });

          accumulator.push(fetchSubProjectId(projectMap[ruleSuite.id], projectMap));
          return accumulator;
        }, [] as any)
    );
  });
};

function fetchSubProjectId(project: Project, projectMap: any) {
  !_.isNil(project) &&
    project.ruleSuites &&
    project.ruleSuites.length > 0 &&
    project.ruleSuites
      .filter((ruleSuite: RuleSuite) => ruleSuite.type === 'project')
      .reduce((accumulator: any, ruleSuite: RuleSuite) => {
        accumulator.push({
          id: ruleSuite.id,
          name: ruleSuite.name,
        });
        accumulator.push(fetchSubProjectId(projectMap[ruleSuite.id], projectMap));
        return accumulator;
      }, [] as any);
}

export const projectSelectors = {
  projectState,
  rootProjectId,
  createdBy,
  projectTreeRootId,
  activeProjectId,
  parentProjectId,
  nestedParents,
  activeWorkspaceId,
  activeProject,
  isLoading,
  projectName,
  ruleSuites,
  projectById,
  projectNameById,
  allProjects,
  documentNameById,
  documentDescById,
  isDocUploadFlag,
  allWorkspaceIdName,
  allNestedSubProjectIds,
  projectFlow,
  projectHierRootProjectId,
  ParentLinksToHide,
};
