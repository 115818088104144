import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink, createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getOperationAST } from 'graphql';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import fetch from 'isomorphic-fetch';
import * as ws from 'ws';
import { createUploadLink } from 'apollo-upload-client';
import gql from 'graphql-tag';
import { setContext } from 'apollo-link-context';

const GRAPHQL_HTTP_ENDPOINT = process.env.GRAPHQL_HTTP_ENDPOINT || '';
const GRAPHQL_WS_ENDPOINT = process.env.GRAPHQL_WS_ENDPOINT || '';

let wsLink: WebSocketLink;
let httpLink: HttpLink | ApolloLink;

export const IS_LOGGED_IN = gql`
  query AuthToken {
    auth {
      accessToken
    }
  }
`;

if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
  // running in browser
  wsLink = new WebSocketLink(
    new SubscriptionClient(GRAPHQL_WS_ENDPOINT, {
      reconnect: true,
    })
  );

  httpLink = createUploadLink({ uri: GRAPHQL_HTTP_ENDPOINT });
} else {
  wsLink = new WebSocketLink({
    uri: GRAPHQL_WS_ENDPOINT,
    options: {
      reconnect: true,
    },
    webSocketImpl: ws,
  });
  httpLink = createHttpLink({
    uri: GRAPHQL_HTTP_ENDPOINT,
    fetch,
  });
}

const networkLink = ApolloLink.split(
  (operation) => {
    const operationAST = getOperationAST(operation.query, operation.operationName);
    return !!operationAST && operationAST.operation === 'subscription';
  },
  wsLink,
  httpLink
);

const cache = new InMemoryCache({
  dataIdFromObject: (object) => object.id || null,
  addTypename: false,
});

const authLink = setContext((_: any, { headers }: any) => {
  let token;
  try {
    const { auth }: any = apollo.readQuery({ query: IS_LOGGED_IN });
    token = auth.accessToken;
  } catch (e) {
    apollo.writeQuery({
      query: IS_LOGGED_IN,
      data: {
        auth: {
          accessToken: '',
        },
      },
    });
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const apollo = new ApolloClient({
  cache,
  link: authLink.concat(networkLink),
  ssrMode: true,
});

export { apollo };
