import { Test } from '../../types/RealtaDocument';
import {
  LOAD_TESTS,
  ADD_TEST,
  UPDATE_TEST,
  SELECT_TEST,
  DELETE_TEST,
  SET_WORD_WRAP,
  SET_AUTO_SUGGESTIONS,
} from './actions';

export interface TestState {
  tests: Test[];
  selectedTestId: string | null;
  wordWrap: boolean;
  autoSuggestions: boolean;
}

const initialState: TestState = {
  tests: [],
  selectedTestId: null,
  wordWrap: true,
  autoSuggestions: true,
};

export interface Action {
  type: string;
  payload?: any;
}

export const testReducer = (state: TestState = initialState, action: Action): TestState => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_TESTS: {
      return {
        ...state,
        tests: payload,
      };
    }
    case SELECT_TEST: {
      return {
        ...state,
        selectedTestId: payload,
      };
    }
    case ADD_TEST: {
      return {
        ...state,
        tests: [...state.tests, payload],
      };
    }
    case UPDATE_TEST: {
      return {
        ...state,
        tests: state.tests.map((test) => (test.uuid === payload.uuid ? payload : test)),
      };
    }
    case DELETE_TEST: {
      return {
        ...state,
        tests: state.tests.filter((test) => test.uuid !== payload),
        selectedTestId: null,
      };
    }
    case DELETE_TEST: {
      return {
        ...state,
        tests: state.tests.filter((test) => test.uuid !== payload),
        selectedTestId: null,
      };
    }
    case SET_WORD_WRAP: {
      return {
        ...state,
        wordWrap: payload,
      };
    }
    case SET_AUTO_SUGGESTIONS: {
      return {
        ...state,
        autoSuggestions: payload,
      };
    }

    default:
      return state;
  }
};
