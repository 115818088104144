import {
  RUN_TEST_CASE,
  SAVE_FACTS,
  UPDATE_RESULTS,
  SAVE_REQUIRED_RESULTS,
  SAVE_UNWANTED_RESULTS,
  SAVE_DESCRIPTION,
  SAVE_TITLE,
  ACTIVATE_TEST_CASE,
  START_WATCH_MODE,
  STOP_WATCH_MODE,
  DELETE_TEST_CASE_OPTIMISTIC,
  CLONE_TEST_CASE_OPTIMISTIC,
  CREATE_NEW_TEST_CASE_OPTIMISTIC,
  SYNC_TEST_CASE_DELETE,
  SYNC_TEST_CASE_UPDATE,
  SORT_TEST_CASES,
  UPDATE_STATUS,
  RERUN_TEST_CASES,
  LOAD_ALL_TESTCASES_OPTIMISTIC,
  LOAD_ALL_TESTCASES,
} from './actions';
import * as _ from 'lodash';

export enum ResultsLineStatus {
  SUCCESS_REQUIRED = 'SUCCESS_REQUIRED',
  SUCCESS_UNWANTED = 'SUCCESS_UNWANTED',
  FAIL_REQUIRED = 'FAIL_REQUIRED',
  FAIL_UNWANTED = 'FAIL_UNWANTED',
  NA = 'N/A',
}

export enum TestCaseStatus {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  NA = 'N/A',
  RUNNING = 'RUNNING',
  NEW = 'NEW',
}

export const TestCaseStatusWeight = {
  [TestCaseStatus.FAIL]: 1,
  [TestCaseStatus.SUCCESS]: 2,
  [TestCaseStatus.RUNNING]: 3,
  [TestCaseStatus.NA]: 4,
  [TestCaseStatus.NEW]: 5,
};

export enum SortByType {
  DATE_CREATED = 'DATE_CREATED',
  DATE_MODIFIED = 'DATE_MODIFIED',
  STATUS = 'STATUS',
  TITLE = 'TITLE',
}

export interface TestCase {
  id: string;
  projectId: string;
  workspaceId: string;
  title: string;
  description: string;
  facts: string;
  requiredResults: string;
  unwantedResults: string;
  results: string;
  requiredResultsStatus: ResultsLineStatus[];
  unwantedResultsStatus: ResultsLineStatus[];
  resultsLineStatus: ResultsLineStatus[];
  status: TestCaseStatus;
  version: number;
}

export const initTestCase: TestCase = {
  id: '',
  title: '',
  projectId: '',
  workspaceId: '',
  description: '',
  facts: '',
  requiredResults: '',
  unwantedResults: '',
  results: '',
  requiredResultsStatus: [],
  unwantedResultsStatus: [],
  resultsLineStatus: [],
  status: TestCaseStatus.NA,
  version: 0,
};

export interface TestCaseState {
  activeId: string;
  testCaseMap: {
    [id: string]: TestCase;
  };
  error: string;
  loading: boolean;
  sortByType: SortByType;
}

const initialState: TestCaseState = {
  activeId: '',
  testCaseMap: {},
  error: '',
  sortByType: SortByType.STATUS,
  loading: false,
};

export interface Action {
  type: string;
  payload?: any;
}

const cleanResultStatus = () => ({
  results: '',
  requiredResultsStatus: [],
  unwantedResultsStatus: [],
  resultsLineStatus: [],
  status: TestCaseStatus.NA,
});

export const testCaseReducer = (state: TestCaseState = initialState, action: Action): TestCaseState => {
  const { type, payload } = action;
  const { activeId } = state;

  switch (type) {
    case LOAD_ALL_TESTCASES: {
      return {
        ...state,
        loading: true,
      };
    }

    case LOAD_ALL_TESTCASES_OPTIMISTIC: {
      let { testCases } = payload;

      testCases = _.flattenDeep(testCases);
      const testCasesKeys = Object.keys(_.flattenDeep(testCases));
      const loadedMap: any = {};
      testCasesKeys.length > 0 &&
        testCasesKeys.map((key) => {
          const testCase = testCases[key];
          loadedMap[testCase.id] = {
            ...initTestCase,
            ...testCase,
          };
        });
      const activeId = (Object.keys(loadedMap).length && Object.keys(loadedMap)[0]) || '';
      return {
        ...initialState,
        activeId,
        loading: false,
        testCaseMap: {
          ...loadedMap,
        },
      };
    }

    case SAVE_TITLE:
    case SAVE_UNWANTED_RESULTS:
    case SAVE_REQUIRED_RESULTS:
    case SAVE_DESCRIPTION:
    case SAVE_FACTS: {
      const cleanStatus = [SAVE_UNWANTED_RESULTS, SAVE_REQUIRED_RESULTS, SAVE_FACTS].includes(type)
        ? { ...cleanResultStatus() }
        : {};

      return {
        ...state,
        testCaseMap: {
          ...state.testCaseMap,
          [activeId]: {
            ...initTestCase,
            ...state.testCaseMap[activeId],
            ...payload,
            version: ++state.testCaseMap[activeId].version,
            ...cleanStatus,
          },
        },
      };
    }

    case RUN_TEST_CASE: {
      const { testCaseId } = payload;
      return {
        ...state,
        testCaseMap: {
          ...state.testCaseMap,
          [testCaseId]: {
            ...state.testCaseMap[testCaseId],
            ...cleanResultStatus(),
            status: TestCaseStatus.RUNNING,
          },
        },
      };
    }

    case UPDATE_RESULTS: {
      const { testCaseId, results, status, requiredResultsStatus, unwantedResultsStatus, resultsLineStatus } = payload;

      return {
        ...state,
        testCaseMap: {
          ...state.testCaseMap,
          [testCaseId]: {
            ...state.testCaseMap[testCaseId],
            results,
            requiredResultsStatus,
            unwantedResultsStatus,
            resultsLineStatus,
            status,
          },
        },
      };
    }

    case SYNC_TEST_CASE_UPDATE:
    case CREATE_NEW_TEST_CASE_OPTIMISTIC: {
      const { testCase } = payload;

      let clean = {};
      if (
        (type === SYNC_TEST_CASE_UPDATE && hasInputChanged(testCase, state.testCaseMap[testCase.id])) ||
        type === CREATE_NEW_TEST_CASE_OPTIMISTIC
      ) {
        clean = { ...cleanResultStatus() };
      }

      const status = type === CREATE_NEW_TEST_CASE_OPTIMISTIC ? TestCaseStatus.NEW : TestCaseStatus.NA;

      let activeId = state.activeId;
      if (type === CREATE_NEW_TEST_CASE_OPTIMISTIC || !activeId) {
        activeId = testCase.id;
      }

      return {
        ...state,
        activeId,
        testCaseMap: {
          ...state.testCaseMap,
          [testCase.id]: {
            ...state.testCaseMap[testCase.id],
            ...testCase,
            ...clean,
            status,
          },
        },
      };
    }

    case CLONE_TEST_CASE_OPTIMISTIC: {
      const { testCase } = payload;
      return {
        ...state,
        activeId: testCase.id,
        testCaseMap: {
          ...state.testCaseMap,
          [testCase.id]: {
            ...testCase,
          },
        },
      };
    }

    case ACTIVATE_TEST_CASE: {
      const { id } = payload;
      return {
        ...state,
        activeId: id,
      };
    }

    case STOP_WATCH_MODE: {
      const updatedStatusMap: any = {};
      Object.keys(state.testCaseMap).forEach((id) => {
        if (state.testCaseMap[id].status === TestCaseStatus.RUNNING) {
          updatedStatusMap[id] = {
            ...state.testCaseMap[id],
            ...cleanResultStatus(),
          };
        }
      });

      return {
        ...state,
        testCaseMap: {
          ...state.testCaseMap,
          ...updatedStatusMap,
        },
      };
    }

    case START_WATCH_MODE: {
      const updatedStatusMap: any = {};
      Object.keys(state.testCaseMap).forEach((id) => {
        updatedStatusMap[id] = {
          ...state.testCaseMap[id],
          ...cleanResultStatus(),
        };
      });

      return {
        ...state,
        testCaseMap: {
          ...state.testCaseMap,
          ...updatedStatusMap,
        },
      };
    }

    case RERUN_TEST_CASES: {
      const updatedStatusMap: any = {};
      Object.keys(state.testCaseMap).forEach((id) => {
        updatedStatusMap[id] = {
          ...state.testCaseMap[id],
          ...cleanResultStatus(),
        };
      });

      return {
        ...state,
        testCaseMap: {
          ...state.testCaseMap,
          ...updatedStatusMap,
        },
      };
    }

    case SYNC_TEST_CASE_DELETE:
    case DELETE_TEST_CASE_OPTIMISTIC: {
      const { testCaseId } = payload;
      const updatedTestCaseMap: any = _.omit(state.testCaseMap, testCaseId);

      let activeId = state.activeId;
      if (testCaseId === state.activeId) {
        if (Object.keys(updatedTestCaseMap).length) {
          activeId = Object.keys(updatedTestCaseMap)[0];
        } else {
          activeId = '';
        }
      }

      return {
        ...state,
        activeId,
        testCaseMap: {
          ...updatedTestCaseMap,
        },
      };
    }

    case SORT_TEST_CASES: {
      const { sortByType } = payload;
      return {
        ...state,
        sortByType,
      };
    }

    case UPDATE_STATUS: {
      const { testCaseId, status } = payload;
      return {
        ...state,
        testCaseMap: {
          ...state.testCaseMap,
          [testCaseId]: {
            ...state.testCaseMap[testCaseId],
            status,
          },
        },
      };
    }
    default:
      return state;
  }
};

function hasInputChanged(newTestCase: TestCase, existingTestCase: TestCase) {
  if (!existingTestCase) {
    return true;
  }

  if (newTestCase.facts !== existingTestCase.facts) {
    return true;
  }

  if (newTestCase.requiredResults !== existingTestCase.requiredResults) {
    return true;
  }

  if (newTestCase.unwantedResults !== existingTestCase.unwantedResults) {
    return true;
  }

  return false;
}
