import { UPDATE_ACCESS_CONTROL, LOAD_PUBLIC_PROJECTS_ID_SUCCESS } from './actions';
import { LOAD_PROJECT_SUCCESS, Project, REFRESH_PROJECT_SUCCESS, LOAD_ALL_PROJECTS_SUCCESS } from '../project';

export enum AccessRoleEnum {
  Owner = 'Owner',
  ReadOnly = 'ReadOnly',
  Edit = 'Edit',
}

export enum AccessTypeEnum {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export interface Access {
  publicAccess: boolean | AccessTypeEnum;
  users: {
    email: string;
    role: AccessRoleEnum;
  }[];
}

export interface AccessState {
  accessMap: {
    [projectId: string]: Access;
  };
  publicProjectIds: string[];
}

const initialState: AccessState = {
  accessMap: {},
  publicProjectIds: [],
};

export interface Action {
  type: string;
  payload?: any;
}

export const accessReducer = (state: AccessState = initialState, action: Action): AccessState => {
  const { type, payload } = action;

  switch (type) {
    case REFRESH_PROJECT_SUCCESS:
    case LOAD_PROJECT_SUCCESS: {
      const { project: project } = payload;
      return {
        ...state,
        accessMap: {
          ...state.accessMap,
          [project.id]: {
            ...state.accessMap[project.id],
            ...project.access,
          },
        },
      };
    }

    case LOAD_ALL_PROJECTS_SUCCESS: {
      const updatedAccessMap =
        (payload &&
          payload.reduce((accumulator: any, project: Project) => {
            accumulator[project.id] = {
              ...project.access,
            };
            return accumulator;
          }, {})) ||
        {};

      return {
        ...state,
        accessMap: {
          ...state.accessMap,
          ...updatedAccessMap,
        },
      };
    }

    case UPDATE_ACCESS_CONTROL: {
      const { projectId, publicAccess, users } = payload;
      return {
        ...state,
        accessMap: {
          ...state.accessMap,
          [projectId]: {
            ...state.accessMap[projectId],
            publicAccess,
            users,
          },
        },
      };
    }

    case LOAD_PUBLIC_PROJECTS_ID_SUCCESS: {
      const { projectIds } = payload;
      return {
        ...state,
        accessMap: {
          ...state.accessMap,
        },
        publicProjectIds: Object.values(projectIds),
      };
    }
    default:
      return state;
  }
};
