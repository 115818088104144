// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-callback-tsx": () => import("./../../../src/pages/callback.tsx" /* webpackChunkName: "component---src-pages-callback-tsx" */),
  "component---src-pages-consent-form-tsx": () => import("./../../../src/pages/consent-form.tsx" /* webpackChunkName: "component---src-pages-consent-form-tsx" */),
  "component---src-pages-consent-view-tsx": () => import("./../../../src/pages/consent-view.tsx" /* webpackChunkName: "component---src-pages-consent-view-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-document-comparison-tsx": () => import("./../../../src/pages/document-comparison.tsx" /* webpackChunkName: "component---src-pages-document-comparison-tsx" */),
  "component---src-pages-document-diffs-tsx": () => import("./../../../src/pages/document-diffs.tsx" /* webpackChunkName: "component---src-pages-document-diffs-tsx" */),
  "component---src-pages-document-merge-tsx": () => import("./../../../src/pages/document-merge.tsx" /* webpackChunkName: "component---src-pages-document-merge-tsx" */),
  "component---src-pages-documents-tsx": () => import("./../../../src/pages/documents.tsx" /* webpackChunkName: "component---src-pages-documents-tsx" */),
  "component---src-pages-hidden-tsx": () => import("./../../../src/pages/hidden.tsx" /* webpackChunkName: "component---src-pages-hidden-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-old-home-tsx": () => import("./../../../src/pages/oldHome.tsx" /* webpackChunkName: "component---src-pages-old-home-tsx" */),
  "component---src-pages-project-tsx": () => import("./../../../src/pages/project.tsx" /* webpackChunkName: "component---src-pages-project-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-trash-tsx": () => import("./../../../src/pages/trash.tsx" /* webpackChunkName: "component---src-pages-trash-tsx" */),
  "component---src-pages-workspace-tsx": () => import("./../../../src/pages/workspace.tsx" /* webpackChunkName: "component---src-pages-workspace-tsx" */)
}

