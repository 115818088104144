import { Test } from '../../types/RealtaDocument';

export const LOAD_TESTS = 'test/LOAD_TESTS';
export const SELECT_TEST = 'test/SELECT_TEST';
export const UPDATE_TEST = 'test/UPDATE_TEST';
export const ADD_TEST = 'test/ADD_TEST';
export const DELETE_TEST = 'test/DELETE_TEST';
export const SET_WORD_WRAP = 'test/SET_WORD_WRAP';
export const SET_AUTO_SUGGESTIONS = 'test/SET_AUTO_SUGGESTIONS';

export const selectTest = (testId: string) => ({
  type: SELECT_TEST,
  payload: testId,
});

export const loadTests = (tests: Test[]) => ({
  type: LOAD_TESTS,
  payload: tests,
});

export const updateTest = (test: Test) => ({
  type: UPDATE_TEST,
  payload: test,
});

export const addTest = (test: Test) => ({
  type: ADD_TEST,
  payload: test,
});

export const deleteTest = (testId: string) => ({
  type: DELETE_TEST,
  payload: testId,
});

export const setWordWrap = (value: boolean) => ({
  type: SET_WORD_WRAP,
  payload: value,
});

export const setAutoSuggestions = (value: boolean) => ({
  type: SET_AUTO_SUGGESTIONS,
  payload: value,
});
