import { takeEvery, put } from 'redux-saga/effects';
import { ENABLE_AUTH, enableAuth, LOGIN_SUCCESS, INIT_AUTH_STATE, hideLogin, LOGOUT_SUCCESS } from './actions';
import { apollo, IS_LOGGED_IN } from '../../apollo/client';

function* enableAuthSaga() {
  sessionStorage.setItem('enableAuth', 'true');
}

function* initAuthStateSaga() {
  const shouldShow = sessionStorage.getItem('enableAuth');
  if (shouldShow) {
    yield put(enableAuth());
  } else {
    yield put(hideLogin());
  }
}

function* loginSuccessSaga({ payload }: any) {
  apollo.writeQuery({
    query: IS_LOGGED_IN,
    data: {
      auth: {
        accessToken: payload.accessToken,
      },
    },
  });
}

function* logoutSuccessSaga() {
  apollo.writeQuery({
    query: IS_LOGGED_IN,
    data: {
      auth: {
        accessToken: '',
      },
    },
  });
}

export const authSaga = [
  takeEvery(ENABLE_AUTH, enableAuthSaga),
  takeEvery(INIT_AUTH_STATE, initAuthStateSaga),
  takeEvery(LOGIN_SUCCESS, loginSuccessSaga),
  takeEvery(LOGOUT_SUCCESS, logoutSuccessSaga),
];
