export const UPDATE_ACCESS_CONTROL = 'access/UPDATE_ACCESS_CONTROL';
export const LOAD_PUBLIC_PROJECTS_ID_REQUEST = 'project/LOAD_PUBLIC_PROJECTS_ID';
export const LOAD_PUBLIC_PROJECTS_ID_SUCCESS = 'project/LOAD_PUBLIC_PROJECTS_ID_SUCCESS';

export const updateAccessControl = (projectId: string, publicAccess: boolean, users: any[]) => ({
  type: UPDATE_ACCESS_CONTROL,
  payload: {
    projectId,
    publicAccess,
    users,
  },
});

export const loadPublicProjectsIdRequest = () => ({
  type: LOAD_PUBLIC_PROJECTS_ID_REQUEST,
});

export const loadPublicProjectsIdSuccess = (projectIds: string[]) => ({
  type: LOAD_PUBLIC_PROJECTS_ID_SUCCESS,
  payload: {
    projectIds,
  },
});
